////
/// ============================================================================
/// SINGULAR
///
/// 01. Map
/// 02. Gallery
/// 03. Brands
/// 04. Videos
/// ============================================================================
///
/// @group pages
////

/**
 * Map
 */
.place {
    &__map {
        height: 25rem;
    }

    .infowindow {
        padding: 10px;
        h4 {
            margin-top: 0;
        }
        p {
            //@include typography-base;
            margin-bottom: 0;
        }
    }
}


/**
 * Gallery
 */
.gallery {
    @include MQ(m) {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
    }
    &__large {
        @include aspect-ratio(99 58);
        margin-bottom: 1rem;
        @include MQ(m) {
            @include span(8);
            margin-bottom: 0;
        }
    }
    &__small {
        figure {
            @include gallery(6);
        }
        @include MQ(m) {
            @include span(last 4);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            figure {
                float: none;
                margin: 0;
                width: 100%;
                &:nth-child(odd),
                &:nth-child(2n+2) {
                    margin: 0;
                }
            }
        }
    }
}


/**
 * Brands
 */
.brands {
    @include clearfix();
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    &__item {
        @include relative(bottom -1px left -1px);
        border-left: 1px solid color-get(gray-light);
        border-bottom: 1px solid color-get(gray-light);
        opacity: 0.5;
        padding: 1rem;
        transition: opacity 0.5s;
        width: 50%;
        @include MQ(m) {
            width: (100% / 3);
        }
        @include MQ(l) {
            width: 25%;
        }

        &:hover {
            opacity: 0.9;
        }
    }
}


/**
 * Infobox
 */
.infobox {
    @include MQ(l) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
    &__content {
        @include MQ(l) {
            @include span(last 5);
            @include suffix(1);
            padding-bottom: 0;
            padding-left: span(1);
        }
    }
    &__media {
        position: relative;
        @include MQ(s) {
            padding-left: gutter() + span(1);
        }
        @include MQ(m) {
            padding-left: gutter() + span(2);
        }
        @include MQ(l) {
            @include span(7);
            padding-left: gutter() + span(1);
        }
        .o-video {
            @include aspect-ratio(154 113);
            float: left;
        }
    }
        &__icon {
            text-align: center;
            @include MQ(s) {
                @include absolute(top 0 right 0);
            }
            svg {
                height: 100px;
                width: 100px;
            }
        }
        &__embed {
            padding-bottom: 1rem;
            padding-top: 1rem;
            @include MQ(s) {
                padding-bottom: 8rem;
                padding-top: 8rem;
            }
            @include MQ(xl) {
                padding-bottom: 5rem;
                padding-top: 5rem;
            }
        }
        &__nav {
            display: flex;
            justify-content: center;
            width: 100%;
            @include MQ(s) {
                @include absolute(right 0 bottom 1rem);
                width: auto;
            }
        }
        &__bg {
            @include absolute(top 0 left 0, -1);
            background: url('images/bg-floral01.png') no-repeat 50% 50%;
            background-size: cover;
            display: none;
            height: 100%;
            width: 100%;
            @include MQ(s) {
                display: block;
                width: span(8);
            }
            @include MQ(l) {
                width: span(5 of 7);
            }
        }
}
////
/// ============================================================================
/// MIXINS
///
/// 01. Aspect Ratio ................ aspect-ratio()
/// 02. Clearfix .................... clearfix()
/// 03. Equal columns ............... equalcolumns()
/// 03. Font weight ................. fw()
/// 03. Font family ................. ff()
/// 04. Hide elements ............... hide()
/// 05. Media Query ................. MQ()
/// 06. Position .................... position()
/// 07. Position Absolute ........... absolute()
/// 08. Position Relative ........... relative()
/// 09. Position Fixed .............. fixed()
/// 10. Position Static ............. static()
/// 11. Position Center ............. center()
/// 12. Reset ....................... reset()
/// 13. Tilted ...................... tilted()
/// 14. Transform Align ............. transform()
/// 15. Triangle .................... triangle()
/// 16. Typography .................. type()
/// ============================================================================
///
/// @group abstracts
////

/// Aspect Ratio
///
/// @link http://goo.gl/eXv9ld
///
/// @parameter {array} $ratio [1 1] - 16 9 | 4 3
///
/// @example scss - Usage
/// .foo {
///    @include aspect-ratio(16 9);
/// }
///
/// @example css - CSS Output
/// .foo:before {
///     content: '';
///     display: block;
///     padding-bottom: 56.25%;
/// }
@mixin aspect-ratio($ratio: 1 1) {
    @if length($ratio) < 2 or length($ratio) > 2 {
        @warn "$ratio Deve essere una lista con 2 valori (es. 16 9).";
    }

    &:before {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
    }
}


/// Clearfix
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
/// .foo {
///    @include clearfix();
/// }
///
/// @example css - CSS Output
/// .foo:after {
///     clear: both;
///     content: '';
///     display: table;
/// }
@mixin clearfix($extend: true) {
    @if $extend == true {
        @extend %clearfix;
    }
    @else {
        &:after {
            clear: both;
            content: '';
            display: table;
        }
    }
}

%clearfix {
    @include clearfix(false);
}


/// Equal columns
///
/// @parameter {length} $items ['article']
///
/// @example scss - Usage
/// .foo {
///    @include equalcolumns('.bar');
/// }
///
/// @example css - CSS Output
/// .foo {
///     width: 100%;
///     overflow: hidden;
/// }
/// .foo .bar {
///     margin-bottom: -99999px !important;
///     padding-bottom: 99999px !important;
/// }
@mixin equalcolumns($items: 'article') {
    width: 100%;
    overflow: hidden;

    #{$items} {
        margin-bottom: -99999px !important;
        padding-bottom: 99999px !important;
    }
}


/// Font weight
///
/// Creates font weight property from $typefaces map.
///
/// @param {String} $typeface - typeface key
/// @param {String} $weight - key of weight
@mixin fw(
    $typeface,
    $weight,
    $typefaces: $typefaces
) {
    @if type-of($typefaces) != 'map' {
        @error "Cannot find #{$typefaces} map found";
    }

    font-weight: map-fetch($typefaces, $typeface weights $weight);
}


/// Font family
///
/// Creates font family property from $typefaces map.
///
/// @param {String} $typeface - typeface key
@mixin ff(
    $typeface,
    $typefaces: $typefaces
) {
    @if type-of($typefaces) != 'map' {
        @error "Cannot find #{$typefaces} map found";
    }

    font-family: map-fetch($typefaces, $typeface stack);
    //font-feature-settings: map-fetch($typefaces, $typeface feature-settings);
}


/// Hide elements
///
/// @parameter {String} $type [hidden] - Variabili: hidden | visuallyhidden | focusable | invisible
///
/// @example scss - Usage
/// .foo {
///    @include hide();
/// }
/// .bar {
///    @include hide(visuallyhidden);
/// }
///
/// @example css - CSS Output
/// .foo {
///     display: none !important;
///     visibility: hidden;
/// }
/// .bar {
///     border: 0;
///     clip: rect(0 0 0 0);
///     height: 1px;
///     margin: -1px;
///     overflow: hidden;
///     padding: 0;
///     position: absolute;
///     width: 1px;
/// }
@mixin hide($type: hidden) {
    @if $type == hidden {
        display: none !important;
        visibility: hidden;
    }
    @if $type == visuallyhidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    @if $type == focusable {
        &:active, &:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto;
        }
    }
    @if $type == invisible {
        visibility: hidden;
    }
}


/// Media Query
///
/// Modifica delle media query di Susy per semplificare l'output in base alla griglia
///
/// @parameter {length} $value - s | m | l | xl
///
/// @link http://goo.gl/tSHvPf
/// @link http://leveluptuts.com/tutorials/susy-tutorials/7-responsive-grids-breakpoint
///
/// @example scss - Usage
/// .foo {
///     @include MQ(xl) {
///         content: 'Media query XL';
///     }
/// }
///
/// @example css - CSS Output
/// [@]media (min-width: 75em) {
///     .foo {
///         content: 'Media query XL';
///     }
/// }
@mixin MQ($value) {
    @if mixin-exists(breakpoint) {
        @include breakpoint(map-get($breakpoints, $value)) {
            @content;
        }
    }
    @else {
        @warn "MQ richiede breakpoint-sass plugin";
    }
}


/// Position
///
/// @link http://goo.gl/TTfZOx Hugo GIRAUDEL
///
/// @parameter {String} $position - Posizione: absolute | relative | fixed | static
/// @parameter {String} $args - Direzione: top right bottom left
/// @parameter {Number} $zindex [false] - Livello dello z-index
///
/// @example scss - Usage
/// .foo {
///    @include position(relative, top -10px right bottom left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: relative;
///     top: -10px;
///     right: 0;
///     bottom: 0;
///     left: 0;
///     zindex: 999;
/// }
@mixin position(
    $position,
    $args: (),
    $zindex: false
) {
    $offsets: top right bottom left;
    $zindex-value: type-of($zindex);

    position: $position;

    @if $zindex-value == number {
        z-index: $zindex;
    }

    @each $offset in $offsets {
        $index: index($args, $offset);

        @if $index {
            @if $index == length($args) {
                #{$offset}: 0;
            }
            @else {
                $next: nth($args, $index + 1);
                @if is-length($next) {
                    #{$offset}: $next;
                }
                @else if index($offsets, $next) {
                    #{$offset}: 0;
                }
                @else {
                    @warn "Invalid value '#{$next}' for offset '#{$offset}'.";
                }
            }
        }
    }
}


/// Position Absolute
///
/// @parameter {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include absolute(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: absolute;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin absolute(
    $args: (),
    $zindex: ()
) {
    @include position(absolute, $args, $zindex);
}


/// Position Relative
///
/// @parameter {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include relative(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: relative;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin relative(
    $args: (),
    $zindex: ()
) {
    @include position(relative, $args, $zindex);
}


/// Position Fixed
///
/// @parameter {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include fixed(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: fixed;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin fixed(
    $args: (),
    $zindex: ()
) {
    @include position(fixed, $args, $zindex);
}


/// Position Static
///
/// @parameter {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include static(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: static;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin static(
    $args: (),
    $zindex: ()
) {
    @include position(static, $args, $zindex);
}


/// Position Center
///
/// @parameter {String} $directions [center] - Direzione: center | top | right | bottom | left
/// @parameter {Number} $height [0] - Altezza elemento
/// @parameter {Number} $width [0] - Larghezza elemento
///
/// @example scss - Usage
/// .foo {
///    @include center(center, 50px, 50px, 999);
/// }
/// .bar {
///    @include center(top, false, 50px);
/// }
/// .baz {
///    @include center(right 1em, 50px, 50px);
/// }
///
/// @example css - CSS Output
/// .foo {
///     height: 50px;
///     left: 50%;
///     margin-left: -25px;
///     margin-top: -25px;
///     position: absolute;
///     top: 50%;
///     width: 50px;
///     zindex: 999;
/// }
/// .bar {
///     margin-left: -25px;
///     position: absolute;
///     top: 0;
///     width: 50px;
///     zindex: 999;
/// }
/// .baz {
///     height: 50px;
///     margin-top: -25px;
///     position: absolute;
///     right: 1em;
///     top: 50%;
///     width: 50px;
///     zindex: 999;
/// }
@mixin center(
    $directions: center,
    $height: false,
    $width: false,
    $zindex: false
) {
    $offsets: top right bottom left;
    $zindex-value: type-of($zindex);

    position: absolute;

    @if $zindex-value == number {
        z-index: $zindex;
    }

    @if $directions == center {
        left: 50%;
        height: $height;
        margin-left: -#{$width/2};
        margin-top: -#{$height/2};
        top: 50%;
        width: $width;
    }
    @else {
        @each $offset in $offsets {
            $index: index($directions, $offset);

            @if $index {
                @if $index == length($directions) {
                    #{$offset}: 0;
                }
                @else {
                    $next: nth($directions, $index + 1);
                    @if is-length($next) {
                        #{$offset}: $next;
                    }
                    @else if index($offsets, $next) {
                        #{$offset}: 0;
                    }
                    @else {
                        @warn "Invalid value '#{$next}' for offset '#{$offset}'.";
                    }
                }
            }
        }
    }
    @if nth($directions, 1) == top {
        left: 50%;
        margin-left: -#{$width/2};
        width: $width;
        @if $height {
            height: $height;
        }
    }
    @if nth($directions, 1) == right {
        margin-top: -#{$height/2};
        top: 50%;
        height: $height;
        @if $width {
            width: $width;
        }
    }
    @if nth($directions, 1) == bottom {
        left: 50%;
        margin-left: -#{$width/2};
        width: $width;
        @if $height {
            height: $height;
        }
    }
    @if nth($directions, 1) == left {
        margin-top: -#{$height/2};
        top: 50%;
        height: $height;
        @if $width {
            width: $width;
        }
    }
}


/// Reset
///
/// @parameter {String} $element [list] - Elementi: list | space
///
/// @example scss - Usage
/// .foo {
///    @include reset(list);
/// }
/// .bar {
///    @include reset(space);
/// }
///
/// @example css - CSS Output
/// .foo {
///     list-style: none;
///     margin-bottom: 0;
///     margin-top: 0;
///     padding-left: 0;
/// }
/// .foo li {
///     margin-left: 0;
/// }
/// .bar {
///     margin-bottom: 0;
///     padding-top: 0;
/// }
@mixin reset($element: list) {
    @if $element == list {
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }
    @if $element == space {
        margin-bottom: 0;
        margin-top: 0;
    }
}


/// Tilted
///
/// Apply a tilted effect by generating a pseudo-element with a diagonal
///
/// @parameter {Angle} $angle - the tilt angle
/// @parameter {Color} $color - the color to be used as background + gradient
/// @parameter {String} $position ['top'] - either `top` or `bottom`
/// @parameter {String} $pseudo ['before'] - either `before` or `after`
///
/// @link https://www.sitepoint.com/tilted-angles-in-sass/
/// @author Hugo Giraudel
@mixin tilted($angle, $color, $position: 'top', $pseudo: 'before') {
    $height: tilted-height($angle);

    position: relative;

    &::#{$pseudo} {
        content: '';
        padding-top: $height;
        position: absolute;
        left: 0;
        right: 0;
        @if ($position == 'top') {
            bottom: 100%;
            background-image: linear-gradient($angle, $color 50%, rgba($color, 0.1) 50.1%, transparent 50%);
        } @else {
            top: 100%;
            background-image: linear-gradient($angle, transparent 50%, rgba($color, 0.1) 50%, $color 50.1%);
        }
    }
}


/// Transform Align
///
/// @link http://goo.gl/GpnBGc
/// @link http://goo.gl/GpnBGc Smashing Magazine - Vertical Centering
///
/// @parameter {String} $direction [null] - Direzioni: null | x | y
/// @parameter {Number} $zindex [false] - Livello dello z-index
///
/// @example scss - Usage
/// .foo {
///     @include transform(null, 10);
/// }
///
/// @example css - CSS Output
/// .foo {
///     left: 50%;
///     position: absolute;
///     top: 50%;
///     transform: translateX(-50%) translateY(-50%);
///     z-index: 10;
/// }
@mixin transform(
    $direction: null,
    $zindex: false
) {
    $zindex-value: type-of($zindex);

    position: absolute;

    @if $direction == x {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }
    @if $direction == y {
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }
    @if $direction == null {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
    @if $zindex-value == number {
        z-index: $zindex;
    }
}

/// Triangle
///
/// @link https://github.com/at-import/toolkit
///
/// @parameter {String} $element [list] - Elementi: list | space
///
/// @example scss - Usage
/// .foo {
///    @include triangle(#000, 10px, 10px, bottom right);
/// }
///
/// @example css - CSS Output
/// .foo {
///    border: 0 solid transparent;
///    border-bottom-color: #000;
///    border-width: 0 0 10px 10px;
///    display: block;
///    height: 0;
///    width: 0;
/// }
@mixin triangle(
    $color: null,
    $height: null,
    $width: null,
    $angle: null
) {
    $color: if($color != null, $color, #000);
    $height: if($height != null, $height, 1em);
    $width: if($width != null, $width, 1em);
    $angle: if($angle != null, $angle, 0);

    @if nth($angle, 1) == "top" or nth($angle, 1) == "bottom" {
        $angle: "#{$angle}";
    }
    @if $angle == "top" {
        $angle: 0;
    }
    @if $angle == "top right" {
        $angle: 45 * 7;
    }
    @if $angle == "right" {
        $angle: 45 * 2;
    }
    @if $angle == "bottom right" {
        $angle: 45 * 1;
    }
    @if $angle == "bottom" {
        $angle: 45 * 4;
    }
    @if $angle == "bottom left" {
        $angle: 45 * 3;
    }
    @if $angle == "left" {
        $angle: 45 * 6;
    }
    @if $angle == "top left" {
        $angle: 45 * 5;
    }

    @if unit($height) == "" or unit($height) == "%" {
        @debug "in triangle #{$height} is not a compatible unit for height."
    }

    @if unit($width) == "" or unit($width) == "%" {
        @debug "in triangle #{$width} is not a compatible unit for width."
    }

    // offset 45deg to make each side start at 0
    $deg: $angle + 45;
    // if units, remove units
    @if unit($deg) == deg {
        $deg: $deg / 1deg;
    }
    // shift to be on a scale from 0 to 90.
    @while $deg > 90 {
        $deg: $deg - 90;
    }
    @while $deg < 0 {
        $deg: $deg + 90;
    }
    // Get a ratio of 90 to multiply by.
    $deg: $deg / 90;

    // make sure metrics are reset
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;

    // run through sides
    @if $angle <= 45 or $angle > 315 {
        border-bottom-color: $color;
        border-width: 0 ($width * abs($deg - 1)) $height ($width * $deg);
    }
    @if $angle > 45 and $angle <= 135 {
        border-left-color: $color;
        border-width: ($height * $deg) 0 ($height * abs($deg - 1)) $width;
    }
    @if $angle > 135 and $angle <= 225 {
        border-top-color: $color;
        border-width: $height ($width * $deg) 0 ($width * abs($deg - 1));
    }
    @if $angle > 225 and $angle <= 315 {
        border-right-color: $color;
        border-width: ($height * abs($deg - 1)) $width ($height * $deg) 0;
    }
}


/// Typography
///
/// @link https://www.smashingmagazine.com/2017/05/fluid-responsive-typography-css-poly-fluid-sizing/
///
/// @example scss - Usage
/// .foo {
///    $fontsize: (480px: 16px, 1380px: 30px);
///    $lineheight: (480px: 22px, 1380px: 40px);
///    @include type('font-size', $fontsize);
///    @include type('line-height', $lineheight);
/// }
///
/// @example css - CSS Output
/// .foo {
///    font-size: 16px;
///    line-height: 22px
/// }
/// [@]media (min-width: 480px) {
///     body {
///         font-size: calc(1.556vw + 8.533px);
///         line-height: calc(2vw + 12.4px)
///     }
/// }
/// [@]media (min-width: 1380px) {
///     body {
///         font-size: 30px;
///         line-height: 40px
///     }
/// }

/**
 * linear-interpolation
 *
 * Calculate the definition of a line between two points
 * @param $map - A SASS map of viewport widths and size value pairs
 * @returns A linear equation as a calc() function
 * @example font-size: linear-interpolation((320px: 18px, 768px: 26px));
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@function linear-interpolation($map) {
    $keys: map-keys($map);
    @if (length($keys) != 2) {
        @error "linear-interpolation() $map must be exactly 2 values";
    }
    // The slope
    $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)))/(nth($keys, 2) - nth($keys,1));

    // The y-intercept
    $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

    // Determine if the sign should be positive or negative
    $sign: "+";

    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    $calc: #{$m * 100}vw #{$sign} #{$b};

    @return calc(#{$calc});
}

/**
 * list-remove
 *
 * Remove an item from a list
 * @param $list - A SASS list
 * @param $index - The list index to remove
 * @returns A SASS list
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@function list-remove($list, $index) {
    $newList: ();
    @for $i from 1 through length($list) {
        @if $i != $index {
            $newList: append($newList, nth($list,$i), 'space');
        }
    }
    @return $newList;
}

/**
 * list-sort
 *
 * Sort a SASS list
 * @param $list - A SASS list
 * @returns A sorted SASS list
 * @requires function list-remove
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@function list-sort($list) {
    $sortedlist: ();
    @while length($list) > 0 {
        $value: nth($list,1);
        @each $item in $list {
            @if $item < $value {
                $value: $item;
            }
        }
        $sortedlist: append($sortedlist, $value, 'space');
        $list: list-remove($list, index($list, $value));
    }
    @return $sortedlist;
}

/**
 * map-sort
 *
 * Sort map by keys
 * @param $map - A SASS map
 * @returns A SASS map sorted by keys
 * @requires function list-sort
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@function map-sort($map) {
    $keys: list-sort(map-keys($map));
    $sortedMap: ();
    @each $key in $keys {
        $sortedMap: map-merge($sortedMap, ($key: map-get($map, $key)));
    }
    @return $sortedMap;
}


/**
 * poly-fluid-sizing
 *
 * Generate linear interpolated size values through multiple break points
 * @param $property - A string CSS property name
 * @param $map - A SASS map of viewport unit and size value pairs
 * @requires function linear-interpolation
 * @requires function map-sort
 * @example [@]include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@mixin type($property, $map) {
    // Get the number of provided breakpoints
    $length: length(map-keys($map));

    // Error if the number of breakpoints is < 2
    @if ($length < 2) {
        @error "poly-fluid-sizing() $map requires at least values"
    }

    // Sort the map by viewport width (key)
    $map: map-sort($map);
    $keys: map-keys($map);

    // Minimum size
    #{$property}: map-get($map, nth($keys,1));

    // Interpolated size through breakpoints
    @for $i from 1 through ($length - 1) {
        @include breakpoint(nth($keys,$i)) {
            #{$property}: linear-interpolation((nth($keys,$i): map-get($map, nth($keys,$i)), nth($keys,($i+1)): map-get($map, nth($keys,($i + 1)))));
        }
    }

    // Maxmimum size
    @include breakpoint(nth($keys,$length)) {
        #{$property}: map-get($map, nth($keys,$length));
    }
}
////
/// ============================================================================
/// LAZYSIZE
/// ============================================================================
///
/// @group vendors
////

.lazyload {
    opacity: 0;
    .no-js & {
        display: none;
    }
}
.lazyloading {
    position: relative;
    &:before {
        @include center(center, 40px, 40px);
        animation: lazyloading 1.0s infinite ease-in-out;
        background: color-get(primary);
        border-radius: 100%;

        content: '';
        display: block;
    }
}
.lazyaspectratio {
    width: 100%;
}

@keyframes lazyloading {
    0% {
        transform: scale(0);
    }
    100% {
        opacity: 0;
        transform: scale(1.0);
    }
}

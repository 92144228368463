////
/// ============================================================================
/// PLAYER
/// ============================================================================
///
/// @group components
////

.player {
    position: relative;

    &:hover {
        .player__icon {
            color: color-get(primary);
        }
    }

    &__image {}

    &__icon {
        @include center(center, 60px, 60px, 1);
        color: #fff;
        &--small {
            $size: 44px;
            height: $size;
            margin-top: $size / -2;
            margin-left: $size / -2;
            width: $size;
        }
        svg {
            @include transform();
            width: 100%;
            height: 100%;
        }
    }
}
////
/// ============================================================================
/// BASE
///
/// 01. Body
/// 02. Layout
/// 03. Section
/// ============================================================================
///
/// @group layout
////

/**
 * Scrollbar
 */
html {
    overflow: auto;
    @include MQ(l) {
        overflow: hidden;
    }
    &::-webkit-scrollbar {
        background: #fff;
        width: 8px
    }

    &::-webkit-scrollbar-thumb {
        background: #bbb;
    }

    &::-webkit-selection,
    &::-moz-selection,
    &::selection {
        background: #f8f8f8;
    }
}

/**
 * Body
 */
body {
    @include debug(breakpoints);
    background: color-get(body);
    color: color-get(text);
}


/**
 * Layout
 */
.l {
    &-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;
        scroll-behavior: smooth;
    }

    &-wrapper {
        //@include debug(baseline);
        @include container;
        @include clearfix;
        @include show-grid;
        padding-left: 2rem;
        padding-right: 2rem;
        position: relative;
        width: 100%;
        z-index: 1;
    }
    &-main {
        display: flex;
        flex-direction: column;
        height: 100vh;
        margin-top: 45px; // Mobile Header Height
        min-height: 100vh;
        position: relative;
        overflow: hidden;

        @include MQ(l) {
            margin-left: 240px;
            margin-top: 0;
        }
        @include MQ(xl) {
            margin-left: 320px;
        }
    }

    &-padding {
        padding: 2rem;
    }
}


/**
 * Section
 */
.section {
    &__header {
        text-align: center;
        @include MQ(m) {
            text-align: left;
        }
        h1, h2 {
            margin-bottom: 0;
        }
    }
    &__content {
        p, ul, ol {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
////
/// ============================================================================
/// HEADER
///
/// 01. Header
/// 02. Top
/// 03. Logo
/// 04. Trigger
/// 05. Navigation
/// 06. Menu
/// 07. Search
/// 08. Copyright
/// ============================================================================
///
/// @link https://github.com/CodyHouse/responsive-sidebar-navigation/
///
/// @group layout
////

/**
 * Header
 */
.header {
    @include absolute(top 0 left 0, 100);
    width: 100%;
    @include MQ(l) {
        @include fixed(bottom 0 right auto);
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        border-right: 1px solid color-get(gray-light);
        padding-bottom: 25px;
        padding-top: 25px;
        width: 240px;
        @include breakpoint(min-height 728px) {
            padding-bottom: 45px;
            padding-top: 45px;
        }
    }
    @include MQ(xl) {
        width: 320px;
    }

    /* Non visibile, usato per verificare la Media Query in jQuery */
    &::before {
        display: none;
        content: 'mobile';
        @include MQ(l) {
            content: 'tablet';
        }
        @include MQ(xl) {
            content: 'desktop';
        }
    }
}


/**
 * Top
 */
.header__top {
    @include absolute(top 0 left 0, 2);
    @include clearfix();
    align-items: center;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 45px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    width: 100%;
    @include MQ(l) {
        background: transparent;
        display: block;
        height: auto;
        padding-bottom: 1rem;
        position: relative;
    }

    /* Logo */
    .logo {
        $map: (600px: 22px, 900px: 26px);
        @include type('font-size', $map);
        @extend %secondary-fontfamily;
        flex: 1 0 auto;
        float: left;
        display: block;
        text-decoration: none;
        @include MQ(l) {
            float: none;
            height: 176px;
            margin-left: auto;
            margin-right: auto;
            width: 130px;
            @include breakpoint(min-height 728px) {
                height: 230px;
                width: 170px;
            }
        }
        @include MQ(xl) {
            height: 284px;
            width: 210px;
        }
        svg {
            display: none;
            @include MQ(l) {
                display: block;
            }
        }
        span {
            @include MQ(l) {
                @include hide(visuallyhidden);
            }
        }
    }
}


/**
 * Logo
 */
.header__logo {
    margin: 0;
    span {
        color: inherit;
    }
}


/**
 * Trigger
 *
 * Visible on mobile devices only
 */
.header__trigger {
    float: right;
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    /* hide text */
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    &:hover {
        color: transparent;
    }
    @include MQ(l) {
        display: none;
    }
    /* Hamburger */
    span,
    span::before,
    span::after {
        position: absolute;
        display: inline-block;
        height: 2px;
        background: color-get(gray-darker);
    }
    /* Line in the center */
    span {
        @include absolute(top 50% right 5px);
        margin-top: -2px;
        transition: background 0.2s;
        width: 18px;
        /* Other 2 lines */
        &::before,
        &::after {
            content: '';
            right: 0;
            transform: translateZ(0);
            backface-visibility: hidden;
            transform-origin: 0 50%;
            transition: transform 0.2s;
        }
        &::before {
            top: -6px; // Top line
            width: 24px;
        }
        &::after {
            top: 6px; // Bottom line
            width: 22px;
        }
    }
    /* Hide line in the center */
    &.nav-is-visible span {
        background: rgba(color-get(gray-darker), 0);
        width: 24px;
        /* Keep visible other 2 lines */
        &::before,
        &::after {
            background: color-get(gray-darker);
            width: 24px;
        }
        &::before {
            transform: translateX(4px) translateY(-3px) rotate(45deg);
        }
        &::after {
            transform: translateX(4px) translateY(2px) rotate(-45deg);
        }
    }
}


/**
 * Navigation
 */
.header__navigation {
    @include absolute(top 0 left 0, 1);
    background: color-get(gray-darker);
    min-height: 45px;
    max-height: 100vh;
    padding-top: 45px;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s 0s, visibility 0s 0.2s;
    visibility: hidden;
    width: 100%;
    &.nav-is-visible {
        max-height: none;
        opacity: 1;
        overflow: visible;
        transition: opacity 0.2s 0s, visibility 0s 0s;
        visibility: visible;
        -webkit-overflow-scrolling: touch;
    }
    ul a {
        $map: (600px: 18px, 1366px: 24px);
        @include type('font-size', $map);
        @extend %secondary-fontfamily;
        display: block;
        color: rgba(#fff, 0.6);
        padding: 1rem 1.5em;
        position: relative;
    }
    & > ul > li > a {
        border-bottom: 1px solid color-get(gray-darker, $lighten: 10%);
    }

    /* Tablet */
    @include MQ(l) {
        background: #fff;
        position: static;
        /* reset style */
        min-height: auto;
        max-height: none;
        opacity: 1;
        overflow: visible;
        padding-top: 0;
        top: auto;
        visibility: visible;
        &.is-fixed {
            position: fixed;
        }
        ul a {
            padding: 0.35rem 1.5em;
        }
        & > ul > li {
            & > a {
                color: color-get(gray-darker);
                border-bottom: 0;
                &::before {
                    @include absolute(top 50% left 0);
                    background: color-get(gray-darker);
                    content: '';
                    display: none;
                    height: 1px;
                    width: 1em;
                }
            }
            /* Current page */
            &.menu__item--current > a,
            &.menu__item--parent-is-current > a {
                &::before {
                    display: block;
                }
            }
        }
    }

    /* Desktop */
    @include MQ(xl) {
        & > ul > li {
            &:hover > a {
                color: color-get(gray-darker, 0.5);
            }
            /* Current page */
            &.menu__item--current > a,
            &.menu__item--parent-is-current > a {
                padding-left: 2.5em;
                &::before {
                    width: 1.5em;
                }
            }
        }

    }
}


/**
 * Menu
 */
.menu {
    &__item {
        &--parent {
            /* Arrow icon */
            > a::after {
                @include absolute(top 50% right 5% bottom auto);
                background: url('images/arrow.svg');
                content: '';
                height: 16px;
                transform: translateY(-50%);
                width: 16px;

                .selected & {
                    transform: translateY(-50%) rotate(180deg);
                }

                @include MQ(l) {
                    display: none;
                }
            }

            @include MQ(l) {
                /* show subnavigation on hover */
                .no-touch .header__navigation &:hover .menu__sub-wrap,
                .header__navigation &.hover .menu__sub-wrap {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__sub-wrap {
        background: color-get(gray-dark);
        display: none;

        .selected & {
            display: block;
        }

        @include MQ(l) {
            @include absolute(top 0);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            left: 100%;
            width: 360px;
            a {
                align-items: center;
                color: darken(#fff, 20%);
                border: none;
                display: flex;
                text-align: left;
                &:hover {
                    color: #fff;
                }
            }
            .menu__item--current a {
                color: #fff;
            }
        }
    }

    &__sub-menu {
        display: none;
        .menu__sub-wrap & {
            display: block;
        }
        svg {
            height: 48px;
            margin-right: 0.5em;
            width: 48px;
        }
        @include MQ(l) {
            max-height: 60vh;
            overflow-y: auto;
            position: relative;
            li {
                &:first-child {
                    padding-top: 3rem;
                }
                &:last-child {
                    padding-bottom: 3rem;
                }
            }
            &::before,
            &::after {
                @include absolute(left 0, 1);
                content: '';
                display: block;
                height: 3rem;
                width: 100%;
            }
            &::before {
                background: linear-gradient(to top, color-get(gray-dark, 0) 0%, color-get(gray-dark) 80%);
                top: 0;
            }
            &::after {
                background: linear-gradient(to bottom, color-get(gray-dark, 0) 0%, color-get(gray-dark) 80%);
                bottom: 0;
            }
        }
    }
}


/**
 * Search
 */
.header__search {
    padding: 2rem 1.5em;
    @include MQ(l) {
        display: none;
    }
}


/**
 * Copyright
 */
.header__copyright {
    font-size: 14px;
    line-height: 16px;
    padding: 1rem 1.5em;
    text-align: center;
    &, a {
        color: rgba(#fff, 0.5);
        text-decoration: none;
    }
    @include MQ(l) {
        padding-bottom: 0;
        text-align: left;
        &, a {
            color: color-get(text, 0.8);
        }
    }
    &-phone {
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        position: relative;
        &:after {
            @include absolute(bottom 0);
            background: color-get(gray-darker);
            content: '';
            display: block;
            height: 1px;
            width: 50px;
        }
        .h5 {
            margin-bottom: 0;
        }
        a {
            @extend %h4;
            @include fw('primary', 'bold');
            @include MQ(l) {
                color: color-get(gray-darker);
            }
        }
    }
}


////
/// ============================================================================
/// ARCHIVE
///
/// 01. Layout
/// 02. Brand
/// 03. List
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.archive {}


/**
 * Brand
 */
.archive-brand {
    opacity: 0.6;
    text-align: center;
    @include MQ(m) {
        bottom: -1rem;
        position: relative;
        text-align: right;
    }
}


/**
 * List
 */
.archive-list {
    &--small {
        .entry-product {
            @include MQ(m) {
                width: span(4);
                &:nth-child(2n) {
                    margin-right: gutter();
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
            @include MQ(xl) {
                width: span(3);
                &:nth-child(3n) {
                    margin-right: gutter();
                }
                &:nth-child(4n),
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__header {}

    &__items {
        @include clearfix();
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;

        .archive-list__header {
            @include MQ(s) {
                align-items: center;
                display: flex;
                text-align: left;
            }
        }
    }
}
////
/// ============================================================================
/// PAGINATION
/// ============================================================================
///
/// @group components
////

.pagination {
    text-align: center;
    &__current,
    &__url,
    &__dots {
        color: color-get(text);
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        margin-left: -1px;
        vertical-align: middle;
        width: 3em;
    }
    &__url {
        border: 1px solid color-get(gray-light);
        color: color-get(text);
        cursor: pointer;
        &:hover {
            background: color-get(gray-darker);
            border-color: color-get(gray-darker);
            color: #fff;
        }
    }
    &__current {
        background: color-get(primary);
        color: #fff;
    }
    svg {
        height: 30px;
    }
}
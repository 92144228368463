/**
 * Links
 */
/**
 * Border radius
 */
.l-wrapper:after, .o-grid:after, .header__top:after, .footer__content:after, .form__fieldset:after, .form__actions:after, .clearfix:after, .cf:after, .archive-list__items:after, .brands:after, .single-product__header:after {
  clear: both;
  content: '';
  display: table;
}

/**
 * linear-interpolation
 *
 * Calculate the definition of a line between two points
 * @param $map - A SASS map of viewport widths and size value pairs
 * @returns A linear equation as a calc() function
 * @example font-size: linear-interpolation((320px: 18px, 768px: 26px));
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
/**
 * list-remove
 *
 * Remove an item from a list
 * @param $list - A SASS list
 * @param $index - The list index to remove
 * @returns A SASS list
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
/**
 * list-sort
 *
 * Sort a SASS list
 * @param $list - A SASS list
 * @returns A sorted SASS list
 * @requires function list-remove
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
/**
 * map-sort
 *
 * Sort map by keys
 * @param $map - A SASS map
 * @returns A SASS map sorted by keys
 * @requires function list-sort
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
/**
 * poly-fluid-sizing
 *
 * Generate linear interpolated size values through multiple break points
 * @param $property - A string CSS property name
 * @param $map - A SASS map of viewport unit and size value pairs
 * @requires function linear-interpolation
 * @requires function map-sort
 * @example [@]include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
 * @author Jake Wilson <jake.e.wilson@gmail.com>
 */
@font-face {
  font-family: "ProximaNovaCondLocal";
  src: local("Proxima Nova Cond"), local("ProximaNovaCond");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Cond";
  src: url("fonts/ProximaNovaCond-Regular.woff2") format("woff2"), url("fonts/ProximaNovaCond-Regular.woff") format("woff"), url("fonts/ProximaNovaCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Cond";
  src: url("fonts/ProximaNovaCond-Semibold.woff2") format("woff2"), url("fonts/ProximaNovaCond-Semibold.woff") format("woff"), url("fonts/ProximaNovaCond-Semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplayLocal";
  src: local("Playfair Display"), local("PlayfairDisplay");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url("fonts/PlayfairDisplay-Regular.woff2") format("woff2"), url("fonts/PlayfairDisplay-Regular.woff") format("woff"), url("fonts/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: "ProximaNovaCondLocal", sans-serif;
}

html.fonts-loaded {
  font-family: ProximaNovaCond, "Proxima Nova Cond", Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, .header__top .logo, .header__navigation ul a {
  font-family: "PlayfairDisplayLocal", sans-serif;
}

.fonts-loaded h1, .fonts-loaded h2, .fonts-loaded h3, .fonts-loaded h4, .fonts-loaded .header__top .logo, .header__top .fonts-loaded .logo, .fonts-loaded .header__navigation ul a, .header__navigation ul .fonts-loaded a {
  font-family: PlayfairDisplay, "Playfair Display", Helvetica, Arial, sans-serif;
}

.lazyload {
  opacity: 0;
}

.no-js .lazyload {
  display: none;
}

.lazyloading {
  position: relative;
}

.lazyloading:before {
  position: absolute;
  left: 50%;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  top: 50%;
  width: 40px;
  animation: lazyloading 1.0s infinite ease-in-out;
  background: #14120c;
  border-radius: 100%;
  content: '';
  display: block;
}

.lazyaspectratio {
  width: 100%;
}

@keyframes lazyloading {
  0% {
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

[data-scrollbar] .scrollbar-track,
[data-scrollbar] .scrollbar-thumb {
  background: transparent;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-loading .slick-list {
  background: #fff url("images/loading.gif") center center no-repeat;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.wpcf7-not-valid-tip {
  color: #ec5840;
  display: block;
}

.screen-reader-response {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wpcf7-validation-errors,
.wpcf7-mail-sent-ok {
  margin-top: 1rem;
}

.grecaptcha-badge {
  display: none;
}

/**
 * Default
 */
html {
  box-sizing: border-box;
  font: 16px/1 sans-serif;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

/**
 * Typography
 */
blockquote,
dt,
figure {
  margin: 0;
  padding: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin-top: 0;
}

h1 a,
h1 a:hover,
h1 a:visited,
h2 a,
h2 a:hover,
h2 a:visited,
h3 a,
h3 a:hover,
h3 a:visited,
h4 a,
h4 a:hover,
h4 a:visited,
h5 a,
h5 a:hover,
h5 a:visited,
h6 a,
h6 a:hover,
h6 a:visited {
  text-decoration: none;
}

address {
  font-style: inherit;
}

a {
  background-color: transparent;
  color: inherit;
  transition: 0.3s;
  -webkit-text-decoration-skip: objects;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * Media
 *
 * 01. Get rid of gap under images by making them display: inline-block; by default
 * 02. Google Maps breaks if `max-width: 100%` acts upon it;
 *     use their selector to remove the effects.
 *     https://github.com/lumapps/lumX/blob/master/core/scss/base/_images.scss
 * 03. SVG Alignment: https://cloudfour.com/thinks/our-svg-icon-process/
 */
img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

img[src*="svg"] {
  height: 100%;
  width: 100%;
}

.gm-style img {
  max-width: none;
  /* 02 */
}

audio,
embed,
iframe,
img,
object,
video {
  display: inline-block;
  /* 01 */
  border: 0;
  height: auto;
  max-width: 100%;
}

svg {
  display: inline-block;
  fill: currentColor;
  height: 100%;
  position: relative;
  top: -0.0625em;
  /* 03 */
  vertical-align: middle;
  width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

/**
 * Form
 *
 * Normalize Version 7.0.0
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /**
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /**
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/**
 * Base Font
 */
html {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}

@media (min-width: 37.5em) {
  html {
    font-size: calc(1.044vw + 11.734px);
  }
}

@media (min-width: 85.375em) {
  html {
    font-size: 26px;
  }
}

@media (min-width: 37.5em) {
  html {
    line-height: calc(1.305vw + 22.167px);
  }
}

@media (min-width: 85.375em) {
  html {
    line-height: 40px;
  }
}

/**
 * Links
 */
a {
  color: #14120c;
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s;
}

a:hover {
  color: black;
  text-decoration: underline;
}

a:hover, a:focus {
  outline: none;
}

/**
 * Typography
 */
h1, h2, h3, h4 {
  font-weight: normal;
  margin-bottom: 1rem;
}

h1 strong, h2 strong, h3 strong, h4 strong {
  font-weight: normal;
}

h1 {
  font-size: 38px;
  line-height: 38px;
}

@media (min-width: 37.5em) {
  h1 {
    font-size: calc(4.178vw + 12.935px);
  }
}

@media (min-width: 85.375em) {
  h1 {
    font-size: 70px;
  }
}

@media (min-width: 37.5em) {
  h1 {
    line-height: calc(4.178vw + 12.935px);
  }
}

@media (min-width: 85.375em) {
  h1 {
    line-height: 70px;
  }
}

h2 {
  font-size: 30px;
  line-height: 30px;
}

@media (min-width: 37.5em) {
  h2 {
    font-size: calc(3.394vw + 9.634px);
  }
}

@media (min-width: 85.375em) {
  h2 {
    font-size: 56px;
  }
}

@media (min-width: 37.5em) {
  h2 {
    line-height: calc(3.394vw + 9.634px);
  }
}

@media (min-width: 85.375em) {
  h2 {
    line-height: 56px;
  }
}

h3 {
  font-size: 26px;
  line-height: 30px;
}

@media (min-width: 37.5em) {
  h3 {
    font-size: calc(1.044vw + 19.734px);
  }
}

@media (min-width: 85.375em) {
  h3 {
    font-size: 34px;
  }
}

@media (min-width: 37.5em) {
  h3 {
    line-height: calc(0.522vw + 26.867px);
  }
}

@media (min-width: 85.375em) {
  h3 {
    line-height: 34px;
  }
}

h4, .header__copyright-phone a, .stack__item-content p {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 37.5em) {
  h4, .header__copyright-phone a, .stack__item-content p {
    font-size: calc(0.522vw + 14.867px);
  }
}

@media (min-width: 85.375em) {
  h4, .header__copyright-phone a, .stack__item-content p {
    font-size: 22px;
  }
}

@media (min-width: 37.5em) {
  h4, .header__copyright-phone a, .stack__item-content p {
    line-height: calc(0.783vw + 19.3px);
  }
}

@media (min-width: 85.375em) {
  h4, .header__copyright-phone a, .stack__item-content p {
    line-height: 30px;
  }
}

h1, h2 {
  color: #14120c;
}

h1 span, h2 span {
  color: #707070;
}

h3, h4, .header__copyright-phone a, .stack__item-content p {
  color: #14120c;
}

h5, .h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #707070;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

@media (min-width: 37.5em) {
  h5, .h5 {
    font-size: calc(0.261vw + 14.433px);
  }
}

@media (min-width: 85.375em) {
  h5, .h5 {
    font-size: 18px;
  }
}

@media (min-width: 37.5em) {
  h5, .h5 {
    line-height: calc(0.783vw + 19.3px);
  }
}

@media (min-width: 85.375em) {
  h5, .h5 {
    line-height: 30px;
  }
}

p,
ul,
ol,
dl {
  margin-bottom: 1rem;
  margin-top: 0;
}

ul, ol {
  padding-left: 20px;
}

p.alignleft,
div.alignleft,
ul.alignleft, ol.alignleft {
  text-align: left;
}

p.alignright,
div.alignright,
ul.alignright, ol.alignright {
  text-align: right;
}

p.aligncenter,
div.aligncenter,
ul.aligncenter, ol.aligncenter {
  text-align: center;
}

/**
 * Navigation
 */
nav ul, nav ol {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

nav a, nav a:hover {
  text-decoration: none;
}

/**
 * Citation
 */
blockquote {
  color: #14120c;
  margin: 1em 0;
}

/**
 * Scrollbar
 */
html {
  overflow: auto;
}

@media (min-width: 75em) {
  html {
    overflow: hidden;
  }
}

html::-webkit-scrollbar {
  background: #fff;
  width: 8px;
}

html::-webkit-scrollbar-thumb {
  background: #bbb;
}

html::-webkit-selection, html::-moz-selection, html::selection {
  background: #f8f8f8;
}

/**
 * Body
 */
body {
  background: white;
  color: #4e4e4e;
}

/**
 * Layout
 */
.l-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}

.l-wrapper {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

.l-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 45px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

@media (min-width: 75em) {
  .l-main {
    margin-left: 240px;
    margin-top: 0;
  }
}

@media (min-width: 85.375em) {
  .l-main {
    margin-left: 320px;
  }
}

.l-padding {
  padding: 2rem;
}

/**
 * Section
 */
.section__header {
  text-align: center;
}

@media (min-width: 56.25em) {
  .section__header {
    text-align: left;
  }
}

.section__header h1, .section__header h2 {
  margin-bottom: 0;
}

.section__content p:first-child, .section__content ul:first-child, .section__content ol:first-child {
  margin-top: 0;
}

.section__content p:last-child, .section__content ul:last-child, .section__content ol:last-child {
  margin-bottom: 0;
}

/**
 * Grid
 */
.o-grid {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
}

.o-grid--align-center {
  align-items: center;
}

.o-grid--align-end {
  align-items: flex-end;
}

@media (min-width: 75em) {
  .o-grid--side-s {
    padding-left: 8.589%;
    padding-right: 8.589%;
  }
}

.o-half {
  margin-bottom: 1rem;
  width: 100%;
}

.o-half:last-child {
  margin-bottom: 0;
}

@media (min-width: 56.25em) {
  .o-half {
    width: 48.466%;
    float: left;
    margin-right: 3.067%;
    margin-bottom: 0;
  }
  .o-half:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
}

@media (min-width: 75em) {
  .u-side-s .o-half {
    width: 48.148%;
    float: left;
    margin-right: 3.704%;
  }
  .u-side-s .o-half:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
  .u-side-s .o-half.section__header {
    padding-right: 6.667%;
  }
}

/**
 * Play
 */
.o-play {
  position: absolute;
  z-index: 1;
  left: 50%;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  top: 50%;
  width: 80px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  color: #fff;
}

.o-play svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 70%;
  height: 70%;
}

/**
 * Video
 */
.o-video {
  position: relative;
}

.o-video__image {
  cursor: pointer;
}

.o-video__iframe {
  z-index: 0;
}

.o-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/**
 * Zoom
 */
.o-zoom {
  position: absolute;
  z-index: 1;
  right: 1rem;
  bottom: 1rem;
  background: #14120c;
  border-radius: 60px;
  height: 60px;
  color: #fff;
  width: 60px;
}

.o-zoom svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1000;
  height: 40px;
  width: 40px;
}

/**
 * Alignments
 */
.u-center {
  text-align: center !important;
}

/**
 * Leading
 */
.u-leading-s {
  padding-top: 2rem;
}

.u-leading-m {
  padding-top: 3rem;
}

.u-leading-l {
  padding-top: 5rem;
}

.u-leading-xl {
  padding-top: 4rem;
}

@media (min-width: 56.25em) {
  .u-leading-xl {
    padding-top: 6rem;
  }
}

@media (min-width: 75em) {
  .u-leading-xl {
    padding-top: 8rem;
  }
}

/**
 * Trailing
 */
.u-trailing-s {
  padding-bottom: 2rem;
}

.u-trailing-m {
  padding-bottom: 3rem;
}

.u-trailing-l {
  padding-bottom: 5rem;
}

.u-trailing-xl {
  padding-bottom: 4rem;
}

@media (min-width: 56.25em) {
  .u-trailing-xl {
    padding-bottom: 6rem;
  }
}

@media (min-width: 75em) {
  .u-trailing-xl {
    padding-bottom: 8rem;
  }
}

/**
 * Padding Side
 */
@media (min-width: 75em) {
  .u-side-s {
    padding-left: 8.589%;
    padding-right: 8.589%;
  }
}

@media (min-width: 56.25em) {
  .u-side-m {
    padding-left: 8.589%;
    padding-right: 8.589%;
  }
}

@media (min-width: 75em) {
  .u-side-m {
    padding-left: 17.178%;
    padding-right: 17.178%;
  }
}

@media (min-width: 56.25em) {
  .u-side-l {
    padding-left: 17.178%;
    padding-right: 17.178%;
  }
}

@media (min-width: 75em) {
  .u-side-l {
    padding-left: 25.767%;
    padding-right: 25.767%;
  }
}

/**
 * Background
 */
.u-bg-graylight {
  background-color: #f0efef;
}

.u-bg-cover {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.u-bg-cover.lazyloaded {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

/**
 * Object-fit
 */
.u-cover {
  font-family: 'object-fit: cover;';
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.u-contain {
  font-family: 'object-fit: contain;';
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/**
 * Ratio
 */
.u-ratio {
  position: relative;
}

.u-ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.u-ratio--1\/1:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.u-ratio--3\/2:before {
  content: '';
  display: block;
  padding-bottom: 66.667%;
}

.u-ratio--16\/9:before {
  content: '';
  display: block;
  padding-bottom: 56.25%;
}

/**
 * Header
 */
.header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  /* Non visibile, usato per verificare la Media Query in jQuery */
}

@media (min-width: 75em) {
  .header {
    position: fixed;
    right: auto;
    bottom: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-right: 1px solid #ebebeb;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 240px;
  }
}

@media (min-width: 75em) and (min-height: 45.5em) {
  .header {
    padding-bottom: 45px;
    padding-top: 45px;
  }
}

@media (min-width: 85.375em) {
  .header {
    width: 320px;
  }
}

.header::before {
  display: none;
  content: 'mobile';
}

@media (min-width: 75em) {
  .header::before {
    content: 'tablet';
  }
}

@media (min-width: 85.375em) {
  .header::before {
    content: 'desktop';
  }
}

/**
 * Top
 */
.header__top {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 100%;
  /* Logo */
}

@media (min-width: 75em) {
  .header__top {
    background: transparent;
    display: block;
    height: auto;
    padding-bottom: 1rem;
    position: relative;
  }
}

.header__top .logo {
  font-size: 22px;
  flex: 1 0 auto;
  float: left;
  display: block;
  text-decoration: none;
}

@media (min-width: 37.5em) {
  .header__top .logo {
    font-size: calc(1.333vw + 14px);
  }
}

@media (min-width: 56.25em) {
  .header__top .logo {
    font-size: 26px;
  }
}

@media (min-width: 75em) {
  .header__top .logo {
    float: none;
    height: 176px;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
  }
}

@media (min-width: 75em) and (min-height: 45.5em) {
  .header__top .logo {
    height: 230px;
    width: 170px;
  }
}

@media (min-width: 85.375em) {
  .header__top .logo {
    height: 284px;
    width: 210px;
  }
}

.header__top .logo svg {
  display: none;
}

@media (min-width: 75em) {
  .header__top .logo svg {
    display: block;
  }
}

@media (min-width: 75em) {
  .header__top .logo span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

/**
 * Logo
 */
.header__logo {
  margin: 0;
}

.header__logo span {
  color: inherit;
}

/**
 * Trigger
 *
 * Visible on mobile devices only
 */
.header__trigger {
  float: right;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  /* hide text */
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  /* Hamburger */
  /* Line in the center */
  /* Hide line in the center */
}

.header__trigger:hover {
  color: transparent;
}

@media (min-width: 75em) {
  .header__trigger {
    display: none;
  }
}

.header__trigger span,
.header__trigger span::before,
.header__trigger span::after {
  position: absolute;
  display: inline-block;
  height: 2px;
  background: #14120c;
}

.header__trigger span {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -2px;
  transition: background 0.2s;
  width: 18px;
  /* Other 2 lines */
}

.header__trigger span::before, .header__trigger span::after {
  content: '';
  right: 0;
  transform: translateZ(0);
  backface-visibility: hidden;
  transform-origin: 0 50%;
  transition: transform 0.2s;
}

.header__trigger span::before {
  top: -6px;
  width: 24px;
}

.header__trigger span::after {
  top: 6px;
  width: 22px;
}

.header__trigger.nav-is-visible span {
  background: rgba(20, 18, 12, 0);
  width: 24px;
  /* Keep visible other 2 lines */
}

.header__trigger.nav-is-visible span::before, .header__trigger.nav-is-visible span::after {
  background: #14120c;
  width: 24px;
}

.header__trigger.nav-is-visible span::before {
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}

.header__trigger.nav-is-visible span::after {
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}

/**
 * Navigation
 */
.header__navigation {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: #14120c;
  min-height: 45px;
  max-height: 100vh;
  padding-top: 45px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.2s 0s, visibility 0s 0.2s;
  visibility: hidden;
  width: 100%;
  /* Tablet */
  /* Desktop */
}

.header__navigation.nav-is-visible {
  max-height: none;
  opacity: 1;
  overflow: visible;
  transition: opacity 0.2s 0s, visibility 0s 0s;
  visibility: visible;
  -webkit-overflow-scrolling: touch;
}

.header__navigation ul a {
  font-size: 18px;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  padding: 1rem 1.5em;
  position: relative;
}

@media (min-width: 37.5em) {
  .header__navigation ul a {
    font-size: calc(0.783vw + 13.3px);
  }
}

@media (min-width: 85.375em) {
  .header__navigation ul a {
    font-size: 24px;
  }
}

.header__navigation > ul > li > a {
  border-bottom: 1px solid #342f1f;
}

@media (min-width: 75em) {
  .header__navigation {
    background: #fff;
    position: static;
    /* reset style */
    min-height: auto;
    max-height: none;
    opacity: 1;
    overflow: visible;
    padding-top: 0;
    top: auto;
    visibility: visible;
  }
  .header__navigation.is-fixed {
    position: fixed;
  }
  .header__navigation ul a {
    padding: 0.35rem 1.5em;
  }
  .header__navigation > ul > li {
    /* Current page */
  }
  .header__navigation > ul > li > a {
    color: #14120c;
    border-bottom: 0;
  }
  .header__navigation > ul > li > a::before {
    position: absolute;
    top: 50%;
    left: 0;
    background: #14120c;
    content: '';
    display: none;
    height: 1px;
    width: 1em;
  }
  .header__navigation > ul > li.menu__item--current > a::before,
  .header__navigation > ul > li.menu__item--parent-is-current > a::before {
    display: block;
  }
}

@media (min-width: 85.375em) {
  .header__navigation > ul > li {
    /* Current page */
  }
  .header__navigation > ul > li:hover > a {
    color: rgba(20, 18, 12, 0.5);
  }
  .header__navigation > ul > li.menu__item--current > a,
  .header__navigation > ul > li.menu__item--parent-is-current > a {
    padding-left: 2.5em;
  }
  .header__navigation > ul > li.menu__item--current > a::before,
  .header__navigation > ul > li.menu__item--parent-is-current > a::before {
    width: 1.5em;
  }
}

/**
 * Menu
 */
.menu__item--parent {
  /* Arrow icon */
}

.menu__item--parent > a::after {
  position: absolute;
  top: 50%;
  right: 5%;
  bottom: auto;
  background: url("images/arrow.svg");
  content: '';
  height: 16px;
  transform: translateY(-50%);
  width: 16px;
}

.selected .menu__item--parent > a::after {
  transform: translateY(-50%) rotate(180deg);
}

@media (min-width: 75em) {
  .menu__item--parent > a::after {
    display: none;
  }
}

@media (min-width: 75em) {
  .menu__item--parent {
    /* show subnavigation on hover */
  }
  .no-touch .header__navigation .menu__item--parent:hover .menu__sub-wrap,
  .header__navigation .menu__item--parent.hover .menu__sub-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    opacity: 1;
    visibility: visible;
  }
}

.menu__sub-wrap {
  background: #3c3c3c;
  display: none;
}

.selected .menu__sub-wrap {
  display: block;
}

@media (min-width: 75em) {
  .menu__sub-wrap {
    position: absolute;
    top: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    left: 100%;
    width: 360px;
  }
  .menu__sub-wrap a {
    align-items: center;
    color: #cccccc;
    border: none;
    display: flex;
    text-align: left;
  }
  .menu__sub-wrap a:hover {
    color: #fff;
  }
  .menu__sub-wrap .menu__item--current a {
    color: #fff;
  }
}

.menu__sub-menu {
  display: none;
}

.menu__sub-wrap .menu__sub-menu {
  display: block;
}

.menu__sub-menu svg {
  height: 48px;
  margin-right: 0.5em;
  width: 48px;
}

@media (min-width: 75em) {
  .menu__sub-menu {
    max-height: 60vh;
    overflow-y: auto;
    position: relative;
  }
  .menu__sub-menu li:first-child {
    padding-top: 3rem;
  }
  .menu__sub-menu li:last-child {
    padding-bottom: 3rem;
  }
  .menu__sub-menu::before, .menu__sub-menu::after {
    position: absolute;
    z-index: 1;
    left: 0;
    content: '';
    display: block;
    height: 3rem;
    width: 100%;
  }
  .menu__sub-menu::before {
    background: linear-gradient(to top, rgba(60, 60, 60, 0) 0%, #3c3c3c 80%);
    top: 0;
  }
  .menu__sub-menu::after {
    background: linear-gradient(to bottom, rgba(60, 60, 60, 0) 0%, #3c3c3c 80%);
    bottom: 0;
  }
}

/**
 * Search
 */
.header__search {
  padding: 2rem 1.5em;
}

@media (min-width: 75em) {
  .header__search {
    display: none;
  }
}

/**
 * Copyright
 */
.header__copyright {
  font-size: 14px;
  line-height: 16px;
  padding: 1rem 1.5em;
  text-align: center;
}

.header__copyright, .header__copyright a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

@media (min-width: 75em) {
  .header__copyright {
    padding-bottom: 0;
    text-align: left;
  }
  .header__copyright, .header__copyright a {
    color: rgba(78, 78, 78, 0.8);
  }
}

.header__copyright-phone {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
}

.header__copyright-phone:after {
  position: absolute;
  bottom: 0;
  background: #14120c;
  content: '';
  display: block;
  height: 1px;
  width: 50px;
}

.header__copyright-phone .h5 {
  margin-bottom: 0;
}

.header__copyright-phone a {
  font-weight: 600;
}

@media (min-width: 75em) {
  .header__copyright-phone a {
    color: #14120c;
  }
}

/**
 * Footer
 */
.footer h5 {
  color: #14120c;
}

.footer p {
  margin-bottom: 0;
}

.footer p a {
  color: #4e4e4e;
}

@media (min-width: 56.25em) {
  .footer__content {
    align-items: flex-end;
    display: flex;
  }
}

@media (min-width: 56.25em) {
  .footer__content .footer-block {
    width: 31.288%;
    float: left;
    margin-right: 3.067%;
    padding-bottom: 0;
  }
  .footer__content .footer-block:last-child {
    float: right;
    margin-right: 0;
  }
}

@media (min-width: 85.375em) {
  .footer__content .footer-block:last-child {
    width: 22.699%;
  }
}

.alert, .wpcf7-validation-errors,
.wpcf7-mail-sent-ok {
  background: #ebebeb;
  border-left: 6px solid #707070;
  padding: 1rem;
  position: relative;
}

.alert--success,
.wpcf7-mail-sent-ok {
  background: #bbf3d0;
  border-color: #3adb76;
  color: #0b3e1e;
}

.alert--info {
  background: #acd9f6;
  border-color: #2199e8;
  color: #062437;
}

.alert--warning {
  background: #ffdf99;
  border-color: #ffae00;
  color: #332300;
}

.alert--error, .wpcf7-validation-errors {
  background: #fad2cb;
  border-color: #ec5840;
  color: #571409;
}

.alert > :first-child, .wpcf7-validation-errors > :first-child, .wpcf7-mail-sent-ok > :first-child {
  margin-top: 0;
}

.alert > :last-child, .wpcf7-validation-errors > :last-child, .wpcf7-mail-sent-ok > :last-child {
  margin-bottom: 0;
}

.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 1px solid #14120c;
  border-radius: 0;
  color: #14120c;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 0.6rem;
  letter-spacing: 3px;
  line-height: normal;
  margin: 0;
  max-width: 100%;
  outline: 0;
  overflow: hidden;
  padding: 0.6rem 3em;
  position: relative;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  transition: all 0.25s ease-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  /* Icon */
}

.btn:hover, .btn:focus, .btn--active {
  border-color: #342f1f;
  color: #342f1f;
  outline: none;
  text-decoration: none;
}

.btn--primary {
  background: #14120c;
  border-color: #14120c;
  color: #fff;
}

.btn--expand {
  display: block;
  width: 100%;
}

.btn--center {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.btn svg {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: none;
  height: 1.5rem;
  margin-left: 0.5em;
  margin-top: .0625em;
  width: 1.5rem;
}

.btn--icon {
  padding-right: 3.5em;
}

.btn--icon svg {
  display: inline-block;
}

.btn[disabled] {
  cursor: default;
  opacity: 0.5;
}

/**
 * Arrow
 */
.arrow {
  background: transparent;
  border: none;
  border-radius: 50%;
  color: #707070;
  cursor: pointer;
  height: 3rem;
  padding: 0;
  width: 3rem;
}

.arrow:hover {
  border-color: #14120c;
  color: #14120c;
}

.arrow svg {
  display: inline-block;
  height: 60px;
  width: 60px;
}

.arrow.slick-disabled {
  border-color: inherit;
  color: inherit;
  opacity: 0.2;
}

/**
 * Input, Select, Textarea
 */
[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"],
select,
textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 0;
  color: #4e4e4e;
  display: block;
  font-family: inherit;
  font-size: 0.8rem;
  outline: 0;
  padding: 0.6rem 0.5em;
  width: 100%;
}

[type="text"]:focus, [type="password"]:focus, [type="date"]:focus, [type="datetime"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="week"]:focus, [type="email"]:focus, [type="number"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="url"]:focus, [type="color"]:focus,
select:focus,
textarea:focus {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

/**
 * Textarea
 */
textarea {
  max-width: 100%;
  resize: vertical;
}

textarea[rows] {
  height: auto;
}

/**
 * Placeholder, Disabled, Readonly
 */
input::placeholder,
textarea::placeholder {
  color: #818181;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background: inherit;
  cursor: default;
}

/**
 * Select
 */
select {
  cursor: pointer;
}

select:not([multiple]) {
  padding-right: 1em;
  background-image: url(data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==);
  background-repeat: no-repeat;
  background-position: 99% 50%;
}

/**
 * Checkbox, Radio, File
 */
form [type="checkbox"],
form [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

form [type="checkbox"] + label,
form [type="radio"] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

form [type="checkbox"] + label[for],
form [type="radio"] + label[for] {
  cursor: pointer;
}

form [type="file"] {
  width: 100%;
}

form [type='file'],
form [type='checkbox'],
form [type='radio'] {
  margin: 0;
}

/**
 * Label
 */
label {
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 0;
  position: relative;
}

@media (min-width: 37.5em) {
  label {
    font-size: calc(0.261vw + 14.433px);
  }
}

@media (min-width: 85.375em) {
  label {
    font-size: 18px;
  }
}

@media (min-width: 37.5em) {
  label {
    line-height: calc(0.783vw + 19.3px);
  }
}

@media (min-width: 85.375em) {
  label {
    line-height: 30px;
  }
}

/**
 * Custom
 */
.form__group {
  margin-bottom: 1rem;
  position: relative;
}

.form__group--privacy {
  cursor: pointer;
}

.form__group--privacy input {
  float: left;
  position: relative;
  top: 5px;
}

.form__group--privacy label {
  padding-left: 2em;
}

.form__label {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  position: relative;
}

.form__actions {
  text-align: center;
}

@media (min-width: 56.25em) {
  .form__col {
    width: 48.466%;
    float: left;
    margin-right: 3.067%;
  }
  .form__col:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
}

.sent .form {
  display: none;
}

/**
 * Search
 */
.search-form__group {
  align-items: stretch;
  display: flex;
  width: 100%;
}

.search-form__query, .search-form__button {
  font-size: 1.2rem;
}

.search-form__query {
  border-radius: 0;
  border-right: 0;
  box-shadow: none;
  flex: 1 1 0;
  height: auto;
  min-width: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.search-form__button {
  border: 2px solid #fff;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  white-space: nowrap;
  width: 100px;
}

.search-form__button .btn {
  align-self: stretch;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: auto;
  padding: 0;
  width: 100%;
}

.search-form__button svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 50%;
  margin-left: 0;
  width: 50%;
}

/**
 * Required
 */
.required {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: block;
  color: #ec5840;
  font-size: 12px;
  right: 0;
  text-transform: uppercase;
}

/**
 * Clearfix
 */
/**
 * Display
 */
.display-hidden {
  display: none !important;
  visibility: hidden;
}

.display-accessibility {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pagination {
  text-align: center;
}

.pagination__current, .pagination__url, .pagination__dots {
  color: #4e4e4e;
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  margin-left: -1px;
  vertical-align: middle;
  width: 3em;
}

.pagination__url {
  border: 1px solid #ebebeb;
  color: #4e4e4e;
  cursor: pointer;
}

.pagination__url:hover {
  background: #14120c;
  border-color: #14120c;
  color: #fff;
}

.pagination__current {
  background: #14120c;
  color: #fff;
}

.pagination svg {
  height: 30px;
}

.player {
  position: relative;
}

.player:hover .player__icon {
  color: #14120c;
}

.player__icon {
  position: absolute;
  z-index: 1;
  left: 50%;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  top: 50%;
  width: 60px;
  color: #fff;
}

.player__icon--small {
  height: 44px;
  margin-top: -22px;
  margin-left: -22px;
  width: 44px;
}

.player__icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;
}

::-moz-selection {
  background: #ebebeb;
  color: inherit;
  text-shadow: none;
}

::selection {
  background: #ebebeb;
  color: inherit;
  text-shadow: none;
}

/**
 * Spotlight
 *
 * @link https://tympanus.net/codrops/2018/01/31/outdoors-template/
 */
@media (min-width: 75em) {
  .js .loading {
    min-height: calc(100vh - 45px);
  }
  .js .loading::before, .js .loading::after {
    content: '';
    position: absolute;
    z-index: 100000;
  }
  .js .loading::before {
    background: #fff;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .js .loading::after {
    animation: loading 0.8s ease-in infinite alternate forwards;
    background: #000;
    height: 60px;
    left: 50%;
    margin: -30px 0 0 -30px;
    opacity: 0;
    pointer-events: none;
    top: 50%;
    width: 60px;
  }
  .js .loading .spotlight__index,
  .js .loading .spotlight__nav,
  .js .loading .spot {
    display: none;
  }
  @keyframes loading {
    to {
      opacity: 1;
      transform: scale3d(0.5, 0.5, 1) rotate(90deg);
    }
  }
}

.spotlight {
  position: relative;
}

.spotlight__nav {
  display: none;
}

.spot__content {
  padding: 2rem;
}

.spot__title {
  display: inline-block;
  margin-bottom: 0;
}

.spot__title span {
  display: inline-block;
}

.spot__title span.word {
  overflow: hidden;
  position: relative;
}

.spot__title span.word span {
  color: #14120c;
}

.spot__subtitle {
  margin-bottom: 0;
  margin-top: 1rem;
  max-width: 600px;
}

.spot__link {
  margin-top: 1rem;
}

.spot__img {
  pointer-events: none;
}

.spot__img-inner {
  width: 100%;
  height: 100%;
}

.spot .fade {
  overflow: hidden;
  position: relative;
}

@media screen and (min-width: 56.25em) and (min-height: 48em) {
  .spotlight {
    height: 85vh;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .spotlight__nav {
    position: absolute;
    z-index: 5;
    bottom: 2rem;
    left: 2rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
    width: 50%;
  }
  .spotlight__arrow {
    flex: 1 0 auto;
    padding-right: 1rem;
  }
  .spotlight__arrow .arrow--prev {
    margin-right: 0.5em;
  }
  .spotlight__arrow .arrow svg {
    transform: rotate(90deg);
  }
  .spotlight__index {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    max-width: 300px;
    position: relative;
  }
  .spotlight__index::after {
    position: absolute;
    left: 50%;
    height: 1px;
    margin-left: -50%;
    margin-top: -0.5px;
    top: 50%;
    width: 100%;
    background: #14120c;
    content: '';
  }
  .spotlight__index-current, .spotlight__index-total {
    background: #fff;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .spotlight__index-total, .spotlight__index-inner {
    display: block;
    text-align: center;
    width: 2rem;
  }
  .spot {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: flex;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .spot--current {
    pointer-events: auto;
  }
  .spot__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 50%;
  }
  .spot__img {
    overflow: hidden;
    height: 100%;
    width: 50%;
    z-index: 99;
  }
  .js .spot:not(.spot--current) {
    pointer-events: none;
  }
  .js .spot:not(.spot--current) .spot__title .word span,
  .js .spot:not(.spot--current) .fade,
  .js .spot:not(.spot--current) .spot__img-inner {
    opacity: 0;
  }
}

@media screen and (max-width: 56.188em), screen and (max-height: 47.938em) {
  .js .spot__title .word span,
  .js .fade,
  .js .spot__img-inner {
    opacity: 1 !important;
    transform: none !important;
  }
}

/**
 * News
 */
.news {
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.news__content {
  position: relative;
  z-index: 10;
}

.news .objects-top,
.news .objects-bottom {
  position: absolute;
  z-index: 1;
  left: 50%;
  height: 1380px;
  margin-left: -1110px;
  margin-top: -690px;
  top: 50%;
  width: 2220px;
  max-width: none;
}

.stack {
  position: relative;
  z-index: 20;
}

.stack__list {
  list-style: none;
  margin: 0 auto;
  height: 340px;
  max-width: 650px;
  padding: 0;
  pointer-events: none;
  position: relative;
  width: 100%;
}

.stack__item {
  background: #fff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  top: -50px;
  transform: scale(0.85);
  transition: all 100ms ease-in-out;
  user-select: none;
  width: 100%;
}

.stack__item:last-child {
  top: 0;
  transform: none;
  width: 100%;
}

.stack__item:nth-last-child(2) {
  top: -30px;
  transform: scale(0.9);
}

.stack__item--prev {
  animation: scalePrev 500ms;
  pointer-events: none;
}

@keyframes scalePrev {
  0% {
    transform: scale(1.1) translateY(100px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.stack__item--next {
  animation: scaleNext 500ms;
  pointer-events: none;
}

@keyframes scaleNext {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.1) translateY(100px);
    opacity: 0;
  }
}

.stack__item-content {
  align-items: center;
  border: 1px solid #3c3c3c;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}

.stack__item-content p {
  color: #707070;
  margin-bottom: 0.5rem;
}

.stack__item-content p:last-child {
  margin-bottom: 0;
}

/**
 * Layout
 */
/**
 * Brand
 */
.archive-brand {
  opacity: 0.6;
  text-align: center;
}

@media (min-width: 56.25em) {
  .archive-brand {
    bottom: -1rem;
    position: relative;
    text-align: right;
  }
}

/**
 * List
 */
@media (min-width: 56.25em) {
  .archive-list--small .entry-product {
    width: 31.288%;
  }
  .archive-list--small .entry-product:nth-child(2n) {
    margin-right: 3.067%;
  }
  .archive-list--small .entry-product:nth-child(3n) {
    margin-right: 0;
  }
}

@media (min-width: 85.375em) {
  .archive-list--small .entry-product {
    width: 22.699%;
  }
  .archive-list--small .entry-product:nth-child(3n) {
    margin-right: 3.067%;
  }
  .archive-list--small .entry-product:nth-child(4n), .archive-list--small .entry-product:last-child {
    margin-right: 0;
  }
}

.archive-list__items {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

@media (min-width: 37.5em) {
  .archive-list__items .archive-list__header {
    align-items: center;
    display: flex;
    text-align: left;
  }
}

/**
 * Map
 */
.place__map {
  height: 25rem;
}

.place .infowindow {
  padding: 10px;
}

.place .infowindow h4 {
  margin-top: 0;
}

.place .infowindow p {
  margin-bottom: 0;
}

/**
 * Gallery
 */
@media (min-width: 56.25em) {
  .gallery {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
  }
}

.gallery__large {
  margin-bottom: 1rem;
}

.gallery__large:before {
  content: '';
  display: block;
  padding-bottom: 58.586%;
}

@media (min-width: 56.25em) {
  .gallery__large {
    width: 65.644%;
    float: left;
    margin-right: 3.067%;
    margin-bottom: 0;
  }
}

.gallery__small figure {
  width: 48.466%;
  float: left;
}

.gallery__small figure:nth-child(2n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.gallery__small figure:nth-child(2n + 2) {
  margin-left: 51.534%;
  margin-right: -100%;
  clear: none;
}

@media (min-width: 56.25em) {
  .gallery__small {
    width: 31.288%;
    float: right;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gallery__small figure {
    float: none;
    margin: 0;
    width: 100%;
  }
  .gallery__small figure:nth-child(odd), .gallery__small figure:nth-child(2n+2) {
    margin: 0;
  }
}

/**
 * Brands
 */
.brands {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.brands__item {
  position: relative;
  bottom: -1px;
  left: -1px;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  opacity: 0.5;
  padding: 1rem;
  transition: opacity 0.5s;
  width: 50%;
}

@media (min-width: 56.25em) {
  .brands__item {
    width: 33.333%;
  }
}

@media (min-width: 75em) {
  .brands__item {
    width: 25%;
  }
}

.brands__item:hover {
  opacity: 0.9;
}

/**
 * Infobox
 */
@media (min-width: 75em) {
  .infobox {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}

@media (min-width: 75em) {
  .infobox__content {
    width: 39.877%;
    float: right;
    margin-right: 0;
    padding-right: 8.589%;
    padding-bottom: 0;
    padding-left: 5.521%;
  }
}

.infobox__media {
  position: relative;
}

@media (min-width: 37.5em) {
  .infobox__media {
    padding-left: 8.589%;
  }
}

@media (min-width: 56.25em) {
  .infobox__media {
    padding-left: 17.178%;
  }
}

@media (min-width: 75em) {
  .infobox__media {
    width: 57.055%;
    float: left;
    margin-right: 3.067%;
    padding-left: 8.589%;
  }
}

.infobox__media .o-video {
  float: left;
}

.infobox__media .o-video:before {
  content: '';
  display: block;
  padding-bottom: 73.377%;
}

.infobox__icon {
  text-align: center;
}

@media (min-width: 37.5em) {
  .infobox__icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.infobox__icon svg {
  height: 100px;
  width: 100px;
}

.infobox__embed {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

@media (min-width: 37.5em) {
  .infobox__embed {
    padding-bottom: 8rem;
    padding-top: 8rem;
  }
}

@media (min-width: 85.375em) {
  .infobox__embed {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
}

.infobox__nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 37.5em) {
  .infobox__nav {
    position: absolute;
    right: 0;
    bottom: 1rem;
    width: auto;
  }
}

.infobox__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: url("images/bg-floral01.png") no-repeat 50% 50%;
  background-size: cover;
  display: none;
  height: 100%;
  width: 100%;
}

@media (min-width: 37.5em) {
  .infobox__bg {
    display: block;
    width: 65.644%;
  }
}

@media (min-width: 75em) {
  .infobox__bg {
    width: 69.892%;
  }
}

/**
 * Entry
 */
.entry-product {
  color: #4e4e4e;
  display: block;
  height: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

@media (min-width: 37.5em) {
  .entry-product {
    width: 48.466%;
    float: left;
    margin-right: 3.067%;
  }
  .entry-product:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 75em) {
  .entry-product {
    width: 31.288%;
  }
  .entry-product:nth-child(2n) {
    margin-right: 3.067%;
  }
  .entry-product:nth-child(3n), .entry-product:last-child {
    margin-right: 0;
  }
}

.entry-product__inner {
  background: #fff;
  border: 1px solid #ebebeb;
  height: 100%;
  padding: 1rem;
  position: relative;
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.entry-product__image {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.entry-product__image-inner {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.entry-product__image-inner img {
  max-height: 350px;
  max-width: 350px;
}

.entry-product__content {
  margin-top: 1rem;
}

.entry-product__title {
  margin-bottom: 0;
}

.entry-product:hover {
  text-decoration: none;
}

.entry-product:hover .entry-product__inner {
  border-color: #14120c;
}

.entry-product:hover .entry-product__title {
  color: #14120c;
}

.entry-product--category .entry-product__image-inner svg {
  max-height: 40%;
  max-width: 40%;
}

.entry-product--brand .entry-product__image-inner img {
  max-height: 100%;
  max-width: 100%;
}

/**
 * Single Product
 */
.single-product__header {
  text-align: left;
}

.single-product__header img {
  float: left;
  height: 100px;
  width: 100px;
}

.single-product__header header {
  margin-left: 120px;
}

@media (min-width: 56.25em) {
  .single-product__header img {
    display: none;
  }
  .single-product__header header {
    margin-left: 0;
  }
}

.single-product__category {
  border-top: 1px solid #ebebeb;
}

/**
 * Navigation
 */
.product-nav {
  background: #fff;
}

.product-nav__list {
  align-items: stretch;
  border-top: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 56.25em) {
  .product-nav__list {
    flex-wrap: nowrap;
  }
}

.product-nav__item {
  flex: 0 1 100%;
}

.product-nav__item a {
  align-items: center;
  color: #4e4e4e;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.product-nav__item a:hover {
  background: #14120c;
  color: #fff;
}

@media (min-width: 56.25em) {
  .product-nav__item a {
    padding: 2rem;
  }
}

.product-nav__item--category span {
  display: none;
}

.product-nav__item--next a {
  flex-direction: row-reverse;
}

.product-nav__item svg {
  height: 48px;
  margin-top: -2px;
  width: 48px;
}

////
/// ============================================================================
/// CONTACT FORM 7
/// ============================================================================
///
/// @group vendors
////

.wpcf7-not-valid-tip {
  color: color-get(error);
  display: block;
}
.screen-reader-response {
  @include hide(visuallyhidden);
}
.wpcf7-validation-errors,
.wpcf7-mail-sent-ok {
  @extend .alert;
}
.wpcf7-validation-errors {
  @extend .alert--error;
}
.wpcf7-mail-sent-ok {
  @extend .alert--success;
}
.wpcf7-validation-errors,
.wpcf7-mail-sent-ok {
  margin-top: 1rem;
}
.grecaptcha-badge {
  display: none;
}
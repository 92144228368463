////
/// ============================================================================
/// ALERT
///
/// 01. Alert
/// 02. Alert Success
/// 03. Alert Info
/// 04. Alert Warning
/// 05. Alert Error
/// ============================================================================
///
/// @group components
////

.alert {
    background: color-get(gray-light);
    border-left: 6px solid color-get(gray);
    padding: 1rem;
    position: relative;
    
    $alerts: success, info, warning, error;
    
    @each $alert in $alerts {
        $color: color-get($alert);
        $background: color-get($alert, $lighten: 30%);
        $text: color-get($alert, $lighten: -40%);

        &--#{$alert} {
            background: $background;
            border-color: $color;
            color: $text;
        }
    }
    
    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
}
////
/// ============================================================================
/// FORM
///
/// 01. Input, Select, Textarea
/// 02. Textarea
/// 03. Placeholder, Disabled, Readonly
/// 04. Select
/// 05. Checkbox, Radio, File
/// 06. Label
/// 07. Custom
/// 08. Custom WPCF7
/// 09. Required
/// 10. Invisible reCaptcha
/// 11. Header Search
/// ============================================================================
///
/// @group components
////

/**
 * Input, Select, Textarea
 */
#{text-inputs()},
select,
textarea {
    appearance: none;
    background-color: #fff;
    border: 1px solid color-get(gray-light);
    border-radius: 0;
    color: color-get(text);
    display: block;
    font-family: inherit;
    font-size: 0.8rem;
    outline: 0;
    padding: 0.6rem 0.5em;
    width: 100%;

    &:focus {
        background-color: #fff;
        border: 1px solid color-get(gray-light, $lighten: -10%);
        box-shadow: 0 0 5px rgba(#000, 0.1);
        outline: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }
}


/**
 * Textarea
 */
textarea {
    max-width: 100%;
    resize: vertical;
    &[rows] {
        height: auto;
    }
}


/**
 * Placeholder, Disabled, Readonly
 */
input,
textarea {
    &::placeholder {
        color: color-get(text, $lighten: 20%);
    }

    &:disabled,
    &[readonly] {
        background: inherit;
        cursor: default;
    }
}


/**
 * Select
 */
select {
    cursor: pointer;

    &:not([multiple]) {
        padding-right: 1em;
        background-image: url(data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==);
        background-repeat: no-repeat;
        background-position: 99% 50%;
    }
}


/**
 * Checkbox, Radio, File
 */
form {
    [type="checkbox"],
    [type="radio"] {
        box-sizing: border-box;
        padding: 0;

        & + label {
            display: inline-block;
            vertical-align: baseline;
            margin-left: 0.5rem;
            margin-right: 1rem;
            margin-bottom: 0;

            &[for] {
                cursor: pointer;
            }
        }
    }

    [type="file"] {
        width: 100%;
    }

    [type='file'],
    [type='checkbox'],
    [type='radio'] {
        margin: 0;
    }
}


/**
 * Label
 */
label {
    $fontsize: (600px: 16px, 1366px: 18px);
    $lineheight: (600px: 24px, 1366px: 30px);
    @include type('font-size', $fontsize);
    @include type('line-height', $lineheight);
    display: block;
    margin: 0;
    position: relative;
}


/**
 * Custom
 */
.form {
    &__fieldset {
        @include clearfix();
    }
    &__group {
        margin-bottom: 1rem;
        position: relative;
        &--privacy {
            cursor: pointer;
            input {
                float: left;
                position: relative;
                top: 5px;
            }
            label {
                padding-left: 2em;
            }
        }
    }
    &__label {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        position: relative;
    }
    &__actions {
        @include clearfix;
        text-align: center;
    }
    &__col {
        @include MQ(m) {
            @include span(6);
            &:nth-child(2n) {
                @include last();
            }
        }
    }

    .sent & {
        display: none;
    }
}


/**
 * Search
 */
.search-form {
    &__group {
        align-items: stretch;
        display: flex;
        width: 100%;
    }
    &__query,
    &__button {
        font-size: 1.2rem;
    }
    &__query {
        border-radius: 0;
        border-right: 0;
        box-shadow: none;
        flex: 1 1 0;
        height: auto;
        min-width: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    &__button {
        border: 2px solid #fff;
        color: #fff;
        display: flex;
        flex: 0 0 auto;
        position: relative;
        white-space: nowrap;
        width: 100px;
        .btn {
            align-self: stretch;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: auto;
            padding: 0;
            width: 100%;
        }
        svg {
            @include transform();
            height: 50%;
            margin-left: 0;
            width: 50%;
        }
    }
}


/**
 * Required
 */
.required {
    @include transform(y);
    display: block;
    color: color-get(error);
    font-size: 12px;
    right: 0;
    text-transform: uppercase;
}
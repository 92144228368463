////
/// ============================================================================
/// FOOTER
///
/// 01. Footer
/// 02. Footer Sitemap Elements
/// 03. Footer Copyright Elements
/// ============================================================================
///
/// @group layout
////

/**
 * Footer
 */
.footer {
    h5 {
        color: color-get(gray-darker);
    }
    p {
        margin-bottom: 0;
        a {
            color: color-get(text);
        }
    }
    &__content {
        @include clearfix();
        @include MQ(m) {
            align-items: flex-end;
            display: flex;
        }
        .footer-block {
            @include MQ(m) {
                @include span(4);
                padding-bottom: 0;
                &:last-child {
                    @include last();
                }
            }
            @include MQ(xl) {
                &:last-child {
                    width: span(3);
                }
            }
        }
    }
}
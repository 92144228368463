////
/// ============================================================================
/// HOME
///
/// 01. Spotlight
/// 02. News
/// ============================================================================
///
/// @group pages
////


/**
 * Spotlight
 *
 * @link https://tympanus.net/codrops/2018/01/31/outdoors-template/
 */
// Loading
.js .loading {
    @include MQ(l) {
        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 100000;
        }
        &::before {
            background: #fff;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
        }
        &::after {
            animation: loading 0.8s ease-in infinite alternate forwards;
            background: #000;
            height: 60px;
            left: 50%;
            margin: -30px 0 0 -30px;
            opacity: 0;
            pointer-events: none;
            top: 50%;
            width: 60px;
        }

        min-height: calc(100vh - 45px); // Header Height
        .spotlight__index,
        .spotlight__nav,
        .spot {
            display: none;
        }

        @keyframes loading {
            to {
                opacity: 1;
                transform: scale3d(0.5, 0.5, 1) rotate(90deg);
            }
        }
    }
}


// Layout
.spotlight {
    position: relative;
    &__nav {
        display: none;
    }
}

// Spot
.spot {
    &__content {
        padding: 2rem;
    }
    &__brand {}
    &__title {
        display: inline-block;
        margin-bottom: 0;
        span {
            display: inline-block;
            &.word {
                overflow: hidden;
                position: relative;
                span {
                    color: color-get(gray-darker);
                }
            }
        }
    }
    &__subtitle {
        margin-bottom: 0;
        margin-top: 1rem;
        max-width: 600px;
    }
    &__link {
        margin-top: 1rem;
    }
    &__img {
        pointer-events: none;
        &-inner {
            width: 100%;
            height: 100%;
        }
    }

    .fade {
        overflow: hidden;
        position: relative;
    }
}

@include breakpoint(screen map-get($breakpoints, m) (min-height 768px)) {
    .spotlight {
        height: 85vh;
        position: relative;
        overflow: hidden;
        width: 100%;
        &__nav {
            @include absolute(bottom 2rem left 2rem, 5);
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-right: 4rem;
            width: 50%;
        }
        &__arrow {
            flex: 1 0 auto;
            padding-right: 1rem;
            .arrow {
                &--prev {
                    margin-right: 0.5em;
                }
                svg {
                    transform: rotate(90deg);
                }
            }
        }
        &__index {
            display: flex;
            flex: 1 0 auto;
            justify-content: space-between;
            max-width: 300px;
            position: relative;
            &::after {
                @include center(center, 1px, 100%);
                background: color-get(gray-darker);
                content: '';
            }
            &-current,
            &-total {
                background: #fff;
                overflow: hidden;
                position: relative;
                z-index: 1;
            }
            &-total,
            &-inner {
                display: block;
                text-align: center;
                width: 2rem;
            }
        }
    }

    .spot {
        @include absolute(top 0 left 0, 0);
        display: flex;
        pointer-events: none;
        width: 100%;
        height: 100%;
        &--current {
            pointer-events: auto;
        }
        &__content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 50%;
        }
        &__img {
            overflow: hidden;
            height: 100%;
            width: 50%;
            z-index: 99;
        }

    }
    .js .spot:not(.spot--current) {
        pointer-events: none;
        .spot__title .word span,
        .fade,
        .spot__img-inner {
            opacity: 0;
        }
    }
}

@include breakpoint((screen (max-width 899px), screen (max-height 767px))) {
    .js .spot__title .word span,
    .js .fade,
    .js .spot__img-inner {
        opacity: 1 !important;
        transform: none !important;
    }
}


/**
 * News
 */
.news {
    pointer-events: auto;
    position: relative;
    overflow: hidden;
    width: 100%;
    &__content {
        position: relative;
        z-index: 10;
    }

    .objects-top,
    .objects-bottom {
        @include center(center, 1380px, 2220px, 1);
        max-width: none;
    }
}
.stack {
    position: relative;
    z-index: 20;
    &__list {
        list-style: none;
        margin: 0 auto;
        height: 340px;
        max-width: 650px;
        padding: 0;
        pointer-events: none;
        position: relative;
        width: 100%;
    }

    &__item {
        background: #fff;
        box-shadow: 0 0 40px rgba(#000, 0.2);
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        padding: 1rem;
        pointer-events: auto;
        position: absolute;
        text-align: center;
        top: -50px;
        transform: scale(0.85);
        transition: all 100ms ease-in-out;
        user-select: none;
        width: 100%;

        &--current {}

        &:last-child {
            top: 0;
            transform: none;
            width: 100%;
        }
        &:nth-last-child(2) {
            top: -30px;
            transform: scale(0.9);
        }

        &--prev {
            animation: scalePrev 500ms;
            pointer-events: none;
            @keyframes scalePrev {
                0% {
                    transform: scale(1.1) translateY(100px);
                    opacity: 0;
                }
                100% {
                    transform: scale(1) translateY(0);
                    opacity: 1;
                }
            }
        }
        &--next {
            animation: scaleNext 500ms;
            pointer-events: none;
            @keyframes scaleNext {
                0% {
                    transform: scale(1) translateY(0);
                    opacity: 1;
                }
                100% {
                    transform: scale(1.1) translateY(100px);
                    opacity: 0;
                }
            }
        }

        &-content {
            align-items: center;
            border: 1px solid color-get(gray-dark);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 1rem;
            p {
                @extend %h4;
                color: color-get(gray);
                margin-bottom: 0.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__arrow {}
}
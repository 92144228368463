////
/// ============================================================================
/// FONTS
/// ============================================================================
///
/// @link https://prowebtype.com/delivering-web-fonts/
/// @link https://jonsuh.com/blog/font-loading-with-font-events/
///
/// @group abstracts
////

@font-face {
    font-family: "ProximaNovaCondLocal";
    src: local('Proxima Nova Cond'), local('ProximaNovaCond');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Proxima Nova Cond";
    src: url("fonts/ProximaNovaCond-Regular.woff2") format("woff2"),
         url("fonts/ProximaNovaCond-Regular.woff") format("woff"),
         url("fonts/ProximaNovaCond-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Proxima Nova Cond";
    src: url("fonts/ProximaNovaCond-Semibold.woff2") format("woff2"),
    url("fonts/ProximaNovaCond-Semibold.woff") format("woff"),
    url("fonts/ProximaNovaCond-Semibold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplayLocal";
    src: local('Playfair Display'), local('PlayfairDisplay');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Playfair Display";
    src: url("fonts/PlayfairDisplay-Regular.woff2") format("woff2"),
    url("fonts/PlayfairDisplay-Regular.woff") format("woff"),
    url("fonts/PlayfairDisplay-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

%primary-fontfamily {
    font-family: "ProximaNovaCondLocal", sans-serif;
    &.fonts-loaded {
        @include ff('primary');
    }
}

%secondary-fontfamily {
    font-family: "PlayfairDisplayLocal", sans-serif;
    .fonts-loaded & {
        @include ff('secondary');
    }
}
////
/// ============================================================================
/// UTILITIES
///
/// 01. Alignments
/// 02. Leading
/// 03. Trailing
/// 03. Padding Side
/// 04. Background
/// ============================================================================
///
/// @group layout
////

/**
 * Alignments
 */
.u-center {
    text-align: center !important;
}


/**
 * Leading
 */
.u-leading {
    &-s {
        padding-top: 2rem;
    }
    &-m {
        padding-top: 3rem;
    }
    &-l {
        padding-top: 5rem;
    }
    &-xl {
        padding-top: 4rem;
        @include MQ(m) {
            padding-top: 6rem;
        }
        @include MQ(l) {
            padding-top: 8rem;
        }
    }
}


/**
 * Trailing
 */
.u-trailing {
    &-s {
        padding-bottom: 2rem;
    }
    &-m {
        padding-bottom: 3rem;
    }
    &-l {
        padding-bottom: 5rem;
    }
    &-xl {
        padding-bottom: 4rem;
        @include MQ(m) {
            padding-bottom: 6rem;
        }
        @include MQ(l) {
            padding-bottom: 8rem;
        }
    }
}


/**
 * Padding Side
 */
.u-side {
    &-s {
        @include MQ(l) {
            @include pad(1, 1);
        }
    }
    &-m {
        @include MQ(m) {
            @include pad(1, 1);
        }
        @include MQ(l) {
            @include pad(2, 2);
        }
    }
    &-l {
        @include MQ(m) {
            @include pad(2, 2);
        }
        @include MQ(l) {
            @include pad(3, 3);
        }
    }

}


/**
 * Background
 */
.u-bg {
    &-graylight {
        background-color: color-get(gray-lighter);
    }
    &-cover {
        @include absolute(top 0 right 0 bottom 0 left 0, 0);
        height: 100%;
        width: 100%;
        &.lazyloaded {
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
        }
    }
}


/**
 * Object-fit
 */
.u-cover {
    font-family: 'object-fit: cover;'; // Polyfill IE11 libraries/ofi.js
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.u-contain {
    font-family: 'object-fit: contain;'; // Polyfill IE11 libraries/ofi.js
    object-fit: contain;
    width: 100%;
    height: 100%;
}


/**
 * Ratio
 */
.u-ratio {
    position: relative;
    & > * {
        @include absolute(top 0 left 0);
        height: 100%;
        width: 100%;
    }
    &--1\/1 {
        @include aspect-ratio(1 1);
    }
    &--3\/2 {
        @include aspect-ratio(3 2);
    }
    &--16\/9 {
        @include aspect-ratio(16 9);
    }
}
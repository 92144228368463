////
/// ============================================================================
/// TYPOGRAPHY
///
/// 01. Base Font
/// 02. Links
/// 03. Typography
/// 04. Navigation
/// ============================================================================
///
/// @group layout
////

/**
 * Base Font
 */
html {
    @extend %primary-fontfamily;
    @include fw('primary', 'regular');

    $fontsize: (600px: 18px, 1366px: 26px);
    $lineheight: (600px: 30px, 1366px: 40px);
    @include type('font-size', $fontsize);
    @include type('line-height', $lineheight);
}


/**
 * Links
 */
a {
    color: $link-color;
    text-decoration: $link-decoration;
    transition: color 0.1s, background-color 0.1s;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
    &:hover, &:focus {
        outline: none;
    }
}


/**
 * Typography
 */
h1, h2, h3, h4 {
    @extend %secondary-fontfamily;
    @include fw('secondary', 'regular');
    margin-bottom: 1rem;
    strong {
        @include fw('secondary', 'regular');
    }
}
h1, %h1 {
    $map: (600px: 38px, 1366px: 70px);
    @include type('font-size', $map);
    @include type('line-height', $map);
}
h2, %h2 {
    $map: (600px: 30px, 1366px: 56px);
    @include type('font-size', $map);
    @include type('line-height', $map);
}
h3, %h3 {
    $fontsize: (600px: 26px, 1366px: 34px);
    $lineheight: (600px: 30px, 1366px: 34px);
    @include type('font-size', $fontsize);
    @include type('line-height', $lineheight);
}
h4, %h4 {
    $fontsize: (600px: 18px, 1366px: 22px);
    $lineheight: (600px: 24px, 1366px: 30px);
    @include type('font-size', $fontsize);
    @include type('line-height', $lineheight);
}

h1, h2, %h1, %h2 {
    color: color-get(gray-darker);
    span {
        color: color-get(gray);
    }
}
h3, h4, %h3, %h4 {
    color: color-get(gray-darker);
}

h5, .h5 {
    $fontsize: (600px: 16px, 1366px: 18px);
    $lineheight: (600px: 24px, 1366px: 30px);
    @include type('font-size', $fontsize);
    @include type('line-height', $lineheight);

    @include fw('primary', 'regular');
    color: color-get(gray);
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

p,
ul,
ol,
dl {
    margin-bottom: 1rem;
    margin-top: 0;
}

ul, ol {
    padding-left: 20px;
}

strong {
    @include fw('primary', 'heavy');
}

p,
div,
ul, ol {
    &.alignleft {
        text-align: left;
    }
    &.alignright {
        text-align: right;
    }
    &.aligncenter {
        text-align: center;
    }
}


/**
 * Navigation
 */
nav {
    ul, ol {
        @include reset(list);
    }
    a, a:hover {
        text-decoration: none;
    }
}


/**
 * Citation
 */
blockquote {
    color: color-get(primary);
    margin: 1em 0;
}
////
/// ============================================================================
/// OBJECTS
///
/// 01. Grid
/// 02. Play
/// 03. Video
/// 04. Zoom
/// ============================================================================
///
/// @group layout
////

/**
 * Grid
 */
.o-grid {
    @include clearfix();
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    &--align-center {
        align-items: center;
    }

    &--align-end {
        align-items: flex-end;
    }

    &--side-s {
        @include MQ(l) {
            @include pad(1, 1);
        }
    }
}

.o-half {
    margin-bottom: 1rem;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
    @include MQ(m) {
        @include span(6);
        margin-bottom: 0;

        &:nth-child(2n) {
            @include last();
        }
    }

    .u-side-s & {
        @include MQ(l) {
            @include span(5 of 10);

            &:nth-child(2n) {
                @include last();
            }

            &.section__header {
                padding-right: span(1 of 10);
            }
        }
    }
}


/**
 * Play
 */
.o-play {
    @include center(center, 80px, 80px, 1);
    background: rgba(#000, 0.3);
    border-radius: 40px;
    color: #fff;
    svg {
        @include transform();
        width: 70%;
        height: 70%;
    }
}


/**
 * Video
 */
.o-video {
    position: relative;

    &__play {}

    &__image {
        cursor: pointer;
        &:hover {
            .video__play {}
        }
    }

    &__iframe {
        z-index: 0;
    }

    iframe {
        @include absolute(top 0 left 0);
        height: 100%;
        width: 100%;
    }
}


/**
 * Zoom
 */
.o-zoom {
    @include absolute(bottom 1rem right 1rem, 1);
    background: color-get(gray-darker);
    border-radius: 60px;
    height: 60px;
    color: #fff;
    width: 60px;
    svg {
        @include transform(null, 1000);
        height: 40px;
        width: 40px;
    }
}
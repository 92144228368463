////
/// ============================================================================
/// VARIABLES
/// ============================================================================
///
/// @group config
////

/// Debug
$debug: false;


/// Breakpoints
///
/// @link https://github.com/at-import/breakpoint/wiki
///
/// @type Map
$breakpoints: (
    s   : 600px,
    m   : 900px,
    l   : 1200px,
    xl  : 1366px
) !global;

@include bkpt-set('to ems', true);


/// Typefaces
///
/// @property {Type} thin       100
/// @property {Type} light      300
/// @property {Type} regular    400
/// @property {Type} bold       700
/// @property {Type} heavy      900
///
/// @example scss - Usage
/// .foo {
///     @include ff(primary);
///     @include fw(primary, light);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-family: Helvetica, Arial, sans-serif;
///     font-feature-settings: 'ss01' 1, 'ss02' 1;
///     font-weight: 400;
/// }
///
/// @type Map
$typefaces: (
    primary: (
            feature-settings: '',
            stack: (ProximaNovaCond, 'Proxima Nova Cond', Helvetica, Arial, sans-serif),
            weights: (
                regular: normal,
                bold: 600
            )
    ),
    secondary: (
            feature-settings: '',
            stack: (PlayfairDisplay, 'Playfair Display', Helvetica, Arial, sans-serif),
            weights: (
                regular: normal
            )
    )
) !global;


/// Susy - Grid
///
/// @type Map
$susy: (
    flow                    : ltr, // rtl | ltr
    math                    : fluid, // fluid | static
    output                  : float, // float | isolate
    gutter-position         : after, // before | after | split | inside | inside-static
    container               : 1680px, // <length> | auto
    container-position      : center, // left | center | right | <length> [*2]
    columns                 : 12, // <number> | <list>
    gutters                 : 1/1.8, // <ratio>
    column-width            : false, // <length> | false/null
    global-box-sizing       : border-box, // content-box | border-box
    last-flow               : to, // to | from
    debug: (
        image               : if($debug == true, show, hide), // show | hide | show-columns | show-baseline
        color               : rgba(#66f, .25), // <color>
        output              : background, // background | overlay
        toggle              : top right, // right | left and top | bottom
    ),
    use-custom: (
        background-image    : false, // <boolean>
        background-options  : false, // <boolean>
        box-sizing          : true, // <boolean>
        clearfix            : true, // <boolean>
        rem                 : true, // <boolean>
    ),
) !global;


/// Color Map
///
/// @property {Color} body          [#fff]      - body
/// @property {Color} text          [#767677]   - text
/// @property {Color} gray-darker   [#1e1e1f]   - gray darker
/// @property {Color} gray-dark     [#767677]   - gray dark
/// @property {Color} gray          [#b1b0af]   - gray
/// @property {Color} gray-light    [#c2c0c0]   - gray light
/// @property {Color} gray-lighter  [#d4d3d2]   - gray lighter
/// @property {Color} success       [#5cb85c]   - success
/// @property {Color} info          [#5bc0de]   - info
/// @property {Color} warning       [#f0ad4e]   - warning
/// @property {Color} danger        [#d9534f]   - danger
/// @property {Color} primary       [#222838]   - primary
/// @property {Color} secondary     [#e31a4e]   - secondary
/// @property {Color} tertiary      [#71bdb4]   - tertiary
///
/// @example scss - Usage
/// .foo {
///     color: color-get(body);
/// }
/// .bar {
///     color: color-get(gray, 0.5, -10%);
/// }
///
/// @example css - CSS Output
/// .foo {
///     color: #fff;
/// }
/// .bar {
///     color: hsla(0, 0%, 52%, .5);
/// }
///
/// @type Map
$colors: (
    body            : #fff,
    text            : #4e4e4e,

    gray-darker     : #14120c,  // H1, H2, etc
    gray-dark       : #3c3c3c,  // Header
    gray            : #707070,  //
    gray-light      : #ebebeb,  // Bordi
    gray-lighter    : #f0efef,  // Sfondi

    success         : #3adb76,
    info            : #2199e8,
    warning         : #ffae00,
    error           : #ec5840,

    primary         : #14120c,
    secondary       : pink,
    tertiary        : pink,
) !global;


/**
 * Links
 */
$link-color                 : color-get(primary) !global;
$link-hover-color           : color-get(primary, $lighten: -10%) !global;
$link-visited-color         : inherit !global;
$link-decoration            : none !global;
$link-hover-decoration      : underline !global;
$link-visited-decoration    : none !global;


/**
 * Border radius
 */
$border-radius: 3px !global;
////
/// ============================================================================
/// PRODUCT
///
/// 01. Entry
/// 02. Single Product
/// ============================================================================
///
/// @group pages
////

/**
 * Entry
 */
.entry-product {
    color: color-get(text);
    display: block;
    height: auto;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;

    @include MQ(s) {
        @include span(6);
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @include MQ(l) {
        width: span(4);
        &:nth-child(2n) {
            margin-right: gutter();
        }
        &:nth-child(3n),
        &:last-child {
            margin-right: 0;
        }
    }

    &__inner {
        background: #fff;
        border: 1px solid color-get(gray-light);
        height: 100%;
        padding: 1rem;
        position: relative;
        transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &__image {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        &-inner {
            align-items: center;
            display: flex;
            justify-content: center;
            text-align: center;
            img {
                max-height: 350px;
                max-width: 350px;
            }
        }
    }

    &__content {
        margin-top: 1rem;
    }

    &__category {}

    &__title {
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: none;
        .entry-product__inner {
            border-color: color-get(primary);
        }
        .entry-product__title {
            color: color-get(primary);
        }
    }

    &--category .entry-product__image-inner svg {
        max-height: 40%;
        max-width: 40%;
    }
    &--brand .entry-product__image-inner img {
        max-height: 100%;
        max-width: 100%;
    }
}


/**
 * Single Product
 */
.single-product {
    &__header {
        @include clearfix();
        text-align: left;
        img {
            float: left;
            height: 100px;
            width: 100px;
        }
        header {
            margin-left: 120px;
        }
        @include MQ(m) {
            img {
                display: none;
            }
            header {
                margin-left: 0;
            }
        }
    }

    &__category {
        border-top: 1px solid color-get(gray-light);
    }
}


/**
 * Navigation
 */
.product-nav {
    background: #fff;
    &__list {
        align-items: stretch;
        border-top: 1px solid color-get(gray-light);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include MQ(m) {
            flex-wrap: nowrap;
        }
    }
    &__item {
        flex: 0 1 100%;
        a {
            align-items: center;
            color: color-get(text);
            display: flex;
            justify-content: center;
            padding: 1rem;
            &:hover {
                background: color-get(primary);
                color: #fff;
            }
            @include MQ(m) {
                padding: 2rem;
            }
        }
        &--category {
            span {
                display: none;
            }
        }
        &--next {
            a {
                flex-direction: row-reverse;
            }
        }
        svg {
            height: 48px;
            margin-top: -2px;
            width: 48px;
        }
    }
}
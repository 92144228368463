////
/// ============================================================================
/// SMOOTH SCROLLBAR
/// ============================================================================
///
/// @link https://idiotwu.github.io/smooth-scrollbar/
///
/// @group vendors
////

[data-scrollbar] {
    .scrollbar-track,
    .scrollbar-thumb {
        background: transparent;
    }
}
////
/// ============================================================================
/// BUTTONS
/// ============================================================================
///
/// @link http://unicorn-ui.com/buttons/ Ispiration
///
/// @group components
////

.btn {
    @include fw('primary', 'heavy');
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 1px solid color-get(primary);
    border-radius: 0;
    color: color-get(primary);
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 0.6rem;
    letter-spacing: 3px;
    line-height: normal;
    margin: 0;
    max-width: 100%;
    outline: 0;
    overflow: hidden;
    padding: 0.6rem 3em;
    position: relative;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    transition: all 0.25s ease-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:focus,
    &--active {
        border-color: color-get(primary, $lighten: 10%);
        color: color-get(primary, $lighten: 10%);
        outline: none;
        text-decoration: none;
    }

    &--primary {
        background: color-get(primary);
        border-color: color-get(primary);
        color: #fff;
    }

    &--expand {
        display: block;
        width: 100%;
    }

    &--center {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    /* Icon */
    svg {
        @include transform(y);
        display: none;
        height: 1.5rem;
        margin-left: 0.5em;
        margin-top: .0625em; // Fix svg position
        width: 1.5rem;
    }

    &--icon {
        padding-right: 3.5em; // Button Padding Side + Svg Size
        svg {
            display: inline-block;
        }
    }

    &[disabled] {
        cursor: default;
        opacity: 0.5;
    }
}


/**
 * Arrow
 */
.arrow {
    background: transparent;
    border: none;
    border-radius: 50%;
    color: color-get(gray);
    cursor: pointer;
    height: 3rem;
    padding: 0;
    width: 3rem;
    &:hover {
        border-color: color-get(primary);
        color: color-get(primary);
    }
    svg {
        display: inline-block;
        height: 60px;
        width: 60px;
    }

    &.slick-disabled {
        border-color: inherit;
        color: inherit;
        opacity: 0.2;
    }
}
////
/// ============================================================================
/// HELP
///
/// 01. Clearfix
/// 02. Display
/// ============================================================================
///
/// @group components
////

/**
 * Clearfix
 */
.clearfix, .cf {
    @include clearfix;
}


/**
 * Display
 */
.display {
    &-hidden {
        @include hide(hidden);
    }
    &-accessibility {
        @include hide(visuallyhidden);
    }
}